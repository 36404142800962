#setup-container {
  @extend .container;

  @media (min-width: $screen-md) {
    padding: 0 2rem;
  }
}

#setupForm {
  width: 100%;
}
