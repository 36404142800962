@mixin hamburger($background, $foreground, $border) {
  width: 2em;
  height: 2em;
  border: 1px solid $border;
  border-radius: $base-border-radius;
  background-color: $background;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 500ms ease;

  &:hover {
    background-color: darken($background, 10%);
  }

  & > span {
    display: block;
    position: absolute;
    top: 0.9375em;
    left: 0.3125em;
    width: 1.25em;
    height: 0.125em;
    background-color: $foreground;
    transition: top 500ms ease, left 500ms ease, transform 500ms ease;

    &:first-child {
      top: 0.5625em;
    }

    &:last-child {
      top: 1.3125em;
    }
  }

  &--open {
    & > span {
      &:first-child,
      &:last-child {
        top: 0.9375em;
      }

      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        left: -3em;
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
/* End Hamburger Menu Styles */
