// Forms
//
// Component Form Styles
//
// Styleguide Components.Forms
@import 'forms/buttons',
        'forms/input',
        'forms/checkbox',
        'forms/date',
        'forms/datepicker',
        'forms/radio',
        'forms/searchbar',
        'forms/validation';

.datepicker {
  position: absolute;
  z-index: 10000 !important;
  background: $brand-white;
  padding: 1.5em 0.75em;
  box-shadow: $base-box-shadow;
}

.selectize {
  & input {
    width: 100%;
  }

  &-dropdown {
    z-index: 9999;
  }
}
/* End Form Styles */
