// - - - - - - - - - - - - - - - - - - -
// - - Font Imports
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 900;
  src: url("fonts/fa-solid-900.eot");
  src: url("fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("fonts/fa-solid-900.woff2") format("woff2"), url("fonts/fa-solid-900.woff") format("woff"), url("fonts/fa-solid-900.ttf") format("truetype"), url("fonts/fa-solid-900.svg#fontawesome") format("svg");
}

// Open Sans
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-Light.ttf") format("truetype");
//   font-weight: 300;
//   font-style: normal;
//   font-variant: normal;
// }
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-LightItalic.ttf") format("truetype");
//   font-weight: 300;
//   font-style: italic;
//   font-variant: normal;
// }
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-Regular.ttf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
//   font-variant: normal;
// }
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-Italic.ttf") format("truetype");
//   font-weight: 400;
//   font-style: italic;
//   font-variant: normal;
// }
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-Semibold.ttf") format("truetype");
//   font-weight: 500;
//   font-style: normal;
//   font-variant: normal;
// }
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-SemiboldItalic.ttf") format("truetype");
//   font-weight: 500;
//   font-style: italic;
//   font-variant: normal;
// }
// @font-face {
//   font-family: open-sans;
//   src: url("fonts/OpenSans-Bold.ttf") format("truetype");
//   font-weight: 700;
//   font-style: normal;
//   font-variant: normal;
// }
