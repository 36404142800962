.emoji-keyboard {
  max-width: 19rem;
  background: $brand-white;
  border: 2px solid $brand-dark-grey;
  position: fixed;
  bottom: -30rem;
  right: 0;
  z-index: 500;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.6);
  transition: bottom 500ms ease;

  &__category-list {
    display: flex;
    margin: 0;
    list-style: none;
    background-color: $brand-light-grey;
    border-bottom: 3px solid $brand-dark-grey;
    overflow-x: scroll;

    &__item {
      width: 3.125rem;
      padding: 0.5rem;
      text-align: center;
      cursor: pointer;
      background-color: transparent;
      position: relative;
      flex: none;
      transition: background-color 500ms ease;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 51%;
        right: 51%;
        bottom: 0;
        height: 0.1875rem;
        background-color: $brand-secondary;
        transition: left 300ms ease, right 300ms ease;
      }

      &:last-child {
        border: 0;
      }

      &--active,
      &:hover {
        &:after {
          left: 0;
          right: 0;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: -2px;
    top: -40px;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: 2px solid $brand-dark-grey;
    cursor: pointer;
    transition: background-color 500ms ease;

    &:after,
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 7px;
      left: 17px;
      margin: 0;
      width: 3px;
      height: 24px;
      background-color: $brand-danger;
      transform: rotate(45deg);
      transition: background-color 500ms ease;
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      background-color: $brand-light-grey;

      &:after,
      &:before {
        background-color: $brand-dark-grey;
      }
    }
  }

  &__emoji-list {
    position: relative;
    overflow: hidden;
    height: 20rem;
  }

  &__category {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1rem);
    padding: 1rem 0.25rem;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-100% - 2rem);
    transition: left 500ms ease;

    &--active {
      left: 0;
    }
  }

  &--active {
    bottom: 4.625rem;
  }

  &--modal {
    & .emoji-keyboard {
      &__emoji-list {
        height: 13rem;
      }
    }

    &.emoji-keyboard--active {
      bottom: 0;
    }
  }
  @media (min-width: $screen-sm) {
    max-width: 28rem;

    &__category-list {
      border-bottom: 2px solid $brand-dark-grey;
      overflow-x: hidden;

      &__item {
        width: 4rem;
        flex: 1;
      }
    }
  }
  @media (min-width: $screen-md) {
    max-width: 24.5rem;

    &--active {
      bottom: 0;
    }

    &__emoji-list {
      height: 13rem;
    }
  }
  @media (min-width: $screen-lg) {
    max-width: 27.75rem;

    &__emoji-list {
      height: 17rem;
    }
  }
  @media (min-width: $screen-xl) {
    max-width: 31.25rem;
  }
}

.emoji-icon {
  width: 3rem;
  padding: 0.25rem;
  border: 2px solid transparent;
  background-color: transparent;
  // border: none;
  opacity: 1;
  cursor: pointer;
  transition: border-color 500ms ease;

  &:hover {
    border-color: $brand-secondary;
  }
  @media (min-width: $screen-sm) {
    width: 3.375rem;
    padding: 0.25rem;
  }
}

.emoji-toggle {
  position: absolute;
  bottom: 1.375rem;
  left: 0.125rem;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("http://images.maintenanceprogram.com/images/icons/emoji/1f600.png");
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  @media (min-width: $screen-sm) {
    width: 3rem;
    height: 3rem;
    background-size: 2.5rem;
  }
}
