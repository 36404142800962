// Bullets
//
// Bullets to indicate the status of an element.  (Active, inactive, pending, etc.)
//
// Markup:
// <div class="bullet {{modifier_class}}"></div>
//
// .bullet--danger - Danger colored bullet
// .bullet--warning - Warning colored bullet
// .bullet--success - Success colored bullet
// .bullet--secondary - Secondary colored bullet
// .bullet--blink - Blinking bullet
//
// Styleguide Components.Utilities.Bullet
.bullet {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: $brand-primary;
  border-radius: 50%;

  &--danger,
  &-danger {
    background-color: $brand-danger;
  }

  &--success,
  &-success {
    background-color: $brand-success;
  }

  &--warning,
  &-warning {
    background-color: $brand-warning;
  }

  &--secondary,
  &-secondary {
    background-color: $brand-secondary;
  }

  &--blink {
    @extend .blink;
  }
}
