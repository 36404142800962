.date-time-bar {
  display: none;
  @media (min-width: $screen-md) {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 17.5em;
    padding: 0.5em 1em;
    font-size: 0.8125rem;
    background-color: $brand-primary;
    box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.65);
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;

    &__date,
    &__time {
      display: inline-block;
      width: 100%;
      text-align: right;
      color: $brand-white;
    }
  }
}

.dashboard-title {
  margin: 0.75em 0 0;
}

#dashboard-cards {
  justify-content: center;
  padding: 2rem 0;
  width: 100%;

  & .card {

    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      order: 4;
    }

    &:nth-child(4) {
      order: 5;
    }

    &:nth-child(5) {
      order: 6;
    }
  }
  @media (min-width: $screen-md) {
    & .card {
      &:nth-child(1) {
        order: 1;
      }
    }
  }
}

#dealer-card {
  order: 1;

  @media (min-width: $screen-md) {
    order: 2;
  }
}
