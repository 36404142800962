#app-error {
  & h1 {
    padding: 1.5rem;
    margin: 0;
  }
}

.page-error {
  margin: 0 auto;
  position: relative;

  &__icon {
    margin: 2rem 0;
    max-height: 12.5rem;
    fill: $brand-dark-grey;
  }

  &__code {
    position: absolute;
    top: 5rem;
    left: 44%;
    font-size: 4.5rem;
    font-weight: 900;
    margin: 0;
    transform: translateX(-50%);
    color: $brand-warning;
  }

  &__message {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
  }

  @media (min-width: $screen-sm) {
    &__icon {
      margin: 2rem 0;
      max-height: 15rem;
    }

    &__code {
      top: 5.75rem;
      left: 46%;
      font-size: 5.5rem;
    }

    &__message {
      font-size: 1.5rem;
      max-width: 30rem;
    }
  }

  @media (min-width: $screen-md) {
    &__icon {
      margin: 2rem 0;
      max-height: 20rem;
    }

    &__code {
      top: 6.5rem;
      left: 45.5%;
      font-size: 8rem;
    }

    &__message {
      font-size: 1.75rem;
      max-width: 40rem;
    }
  }

  @media (min-width: $screen-lg) {
    &__icon {
      margin: 2rem 0;
      max-height: 25rem;
    }

    &__code {
      top: 7.5rem;
      font-size: 10rem;
    }

    &__message {
      font-size: 2rem;
      max-width: 50rem;
    }
  }
}
