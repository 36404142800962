#shepherdModalOverlayContainer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999998;
  fill: #fff;
  opacity: 0.875;
  transition: all 300ms ease;
}

.tippy-popper {
  z-index: 9999999 !important;
}

.tippy-tooltip {
  padding: 1rem;
  background-color: $brand-white;
  border: 3px solid $brand-secondary;
  box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.15);
}

.tippy-popper[x-placement^=left] .tippy-arrow {
  right: -11px;
  border-left-color: $brand-secondary;
}

.tippy-popper[x-placement^=right] .tippy-arrow {
  left: -11px;
  border-right-color: $brand-secondary;
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  bottom: -11px;
  border-top-color: $brand-secondary;
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
  top: -11px;
  border-bottom-color: $brand-secondary;
}

.shepherd-title {
  margin: 0;
}

.shepherd-text {
  font-size: 1rem;
  color: $brand-dark-grey;
}

.shepherd-buttons {
  display: flex;
  justify-content: center;
  margin: 0;
  list-style: none;

  & li {
    margin: 0 0.5rem;
  }
}

.shepherd-modal-target {
  &.shepherd-enabled {
    outline: 3px solid $brand-secondary !important;
    outline-offset: -3px;
  }

  &:focus {
    outline: none;
  }
}

@media (min-width: $screen-md) {
  .tippy-tooltip {
    min-width: 30rem;
  }
}

@media (min-width: $screen-lg) {
  .tippy-tooltip {
    min-width: 35rem;
  }
}

.shepherd-outline-container {
  position: relative;
}

.shepherd-outline {
  position: absolute;
  top: -0.75rem;
  left: -0.75rem;
  right: -0.75rem;
  bottom: -0.75rem;
  z-index: -1;

  &.shepherd-enabled {
    z-index: 1;
  }
}
