// Properties
//
// Properties are key/value pairs of information.
//
// Markup:
// <div class="property"><span class="property__key">Key Name</span><span class="property__value">Value of property</span></div>
//
// Styleguide Components.Utilities.Properties
.property {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-top;
  font-size: 0.75em;
  margin: 0 0 1.5em;

  &__key {
    width: 100%;
    margin: 0 1em 0.5em 0;
    font-weight: bold;
  }

  &__value {
    width: 100%;
    min-height: 1rem;
    white-space: pre;

    &--success {
      font-weight: 500;
      color: $brand-success;
    }
  }

  &:last-child {
    margin: 0;
  }

  @media (min-width: $screen-sm) {
    font-size: 0.8125em;
  }
  @media (min-width: $screen-md) {
    font-size: 0.875em;
    padding: 0 2em 0 0;
  }
  @media (min-width: $screen-lg) {
    font-size: 0.9375em;
  }
  @media (min-width: $screen-xl) {
    font-size: 1em;
  }
}


.properties-container {
  padding: 0 1em;
  margin: 1em 0;
  list-style: none;

  @media (min-width: $screen-lg) {
    padding: 0 2em;
    margin: 2em 0;
  }

  &--report-info {
    display: flex;
    flex-wrap: wrap;
    background-color: $brand-white;
    border: 1px solid $brand-medium-grey;
    box-shadow: $base-box-shadow;
    padding: 1rem !important;

    & .property {
      flex: 1 0 100%;
    }

    @media (min-width: $screen-sm) {
      padding: 1.5rem !important;

      & .property {
        flex: 1 0 50%;
      }
    }
  }
}

.property-set {
  border-bottom: 1px solid $brand-grey;
  margin: 0 0 1.5rem;

  .property {
    text-align: center;
  }

  &:last-child {
    border-bottom: none;
    margin: 0;
  }
}

.property-divider {
    clear: both;
    margin:  1rem 0;
    border:  0;
    border-top: 2px solid $brand-medium-grey;

    &:last-child {
      margin: 0;
      border-top: none;
    }
}

.property-block {
  @extend .clearfix;
  margin: 0.5rem 0;
  border-bottom: 1px solid $brand-medium-grey;
  font-size: 0.875rem;

  & .property {
    margin: 0.75rem 0;
  }

  &:last-child {
    border-bottom: 0;
  }
}
