// Labels
//
// A label used for indicating features, attributes, etc about elements.
//
// Markup:
// <span class="label {{modifier_class}}">Item Label</span>
//
// .label--warning - Warning colored label
// .label--success - Success colored label
// .label--danger - Danger colored label
// .label--secondary - Secondary colored label
// .label--dark - Dark colored label
// .label--light - Light colored label
// .label--big - Larger label
//
// Styleguide Components.Utilities.Labels
.label {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 2em;
  color: $brand-white;
  background-color: $brand-primary;

  @media (min-width: $screen-sm) {
  }

  &--danger,
  &-danger {
    background-color: $brand-danger;
  }

  &--light,
  &-light {
    color: $brand-dark-grey;
    background-color: $brand-light-grey;
  }

  &--dark,
  &-dark {
    background-color: $brand-dark-grey;
  }

  &--secondary,
  &-secondary,
  &-default,
  &--default {
    background-color: $brand-secondary;
  }

  &--success,
  &-success {
    background-color: $brand-success;
  }

  &--warning,
  &-warning {
    background-color: $brand-warning;
  }

  &--big {
    padding: 0.5em 1em;
  }
}
/* End Label Styles */
