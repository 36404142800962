.panel {
  background: $brand-white;
  padding: 1em;
  margin: 0 0 1em;
  overflow: hidden;

  @media (min-width: $screen-sm) {
    border-radius: $base-border-radius;
  }
}
