// Hover Underline
//
// Hover affect that appears above or below the hovered element
//
// Markup:
// <div class="underline {{modifier_class}}">Text to be Underlined</div>
//
// .underline--top - Hover line appears above element
// .underline--bottom - Hover line appears below element
//
// Styleguide Components.Utilities.HoverUnderline
.underline {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 55%;
    right: 55%;
    height: 0.125rem;
    background-color: $brand-primary;
    transition: left 500ms ease, right 500ms ease;
  }

  &:hover,
  &:focus {
    &:after {
      left: 0;
      right: 0;
    }
  }

  &--top {
    &:after {
      top: 0;
    }
  }

  &--bottom {
    &:after {
      bottom: 0;
    }
  }

  &--secondary {
    &:after {
      background-color: $brand-secondary;
    }
  }

  &--danger {
    &:after {
      background-color: $brand-danger;
    }
  }

  &--success {
    &:after {
      background-color: $brand-success;
    }
  }

  &--warning {
    &:after {
      background-color: $brand-warning;
    }
  }
}

.underline-top {
  @extend .underline;

  &:after {
    top: 0;
  }
}

.underline-bottom {
  @extend .underline;

  &:after {
    bottom: 0;
  }
}

$outline-speed: 125ms !default;

.outline-cw {
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    overflow: hidden;
  }

  &:before {
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    transition: width $outline-speed linear ($outline-speed * 3), height $outline-speed linear ($outline-speed * 2), border-color 0ms ($outline-speed * 4);
  }

  &:after {
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: width $outline-speed linear $outline-speed, height $outline-speed linear, border-color 0ms ($outline-speed * 2);
  }

  &:hover {

    &:before,
    &:after {
      width: 100%;
      height: 100%;
      border-color: $brand-primary;
    }

    &:before {
      transition: width $outline-speed linear, height $outline-speed linear $outline-speed;
    }

    &:after {
      transition: width $outline-speed linear ($outline-speed * 2), height $outline-speed linear ($outline-speed * 3), border-color 0ms ($outline-speed * 2);
    }
  }

  &--danger {
    &:hover {
      &:before,
      &:after {
        border-color: $brand-danger;
      }
    }
  }

  &--success {
    &:hover {
      &:before,
      &:after {
        border-color: $brand-success;
      }
    }
  }

  &--warning {
    &:hover {
      &:before,
      &:after {
        border-color: $brand-warning;
      }
    }
  }
}
