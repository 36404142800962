.dealer-comments {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 1rem 0;
  width: 100%;

  &__item {
    display: flex;
    width: 100%;
    margin: 0.5rem 0;
    padding: 0;
    background-color: #eee;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 4px transparentize($brand-dark-grey, 0.4);

    &__details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex: 1;
      padding: 1rem;

      &__date,
      &__user {
        display: inline-block;
        font-size: 0.875rem;
        font-weight: 500;
      }

      &__user {
        align-self: flex-end;
      }

      &__comment {
        width: 100%;
        margin: 0.75rem 0 0;
      }
    }

    &__button {
      border: none;
      width: 0;
      height: 100%;
      padding: 0;
      border-radius: 0;
      border: 0;
      box-shadow: none;
      overflow: hidden;
      transition: width 800ms ease-in;
    }

    &__input {
      margin: 0.5rem;
      min-height: 3rem;
    }

    &:hover,
    &--editing {
      & .dealer-comments__item {
        &__button {
          width: 5rem;
          transition: width 300ms ease-in, background-color 500ms ease;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    &__item {
      width: calc(50% - 1rem);
      margin: 0.5rem;
    }
  }
}

.contract-comments {
  margin: 1.5rem 0;
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: 0.5rem;
    color: $brand-white;
    background-color: $brand-warning;

    &__comment {
      flex: 1;
      padding: 0 1rem;
      font-weight: 500;
    }

    &__icon {
      fill: $brand-white;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }
  }
}
