// - - - - - - - - - - - - - - - - - - -
// - - Builds grid based on variables
// - - defined in _variables.scss

img {
  height: auto;
  max-width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: ($gutter-width / 2);
  margin: 0 auto;
  position: relative;
  width: $container-sm;
  max-width: 100%;

  &--small {
    width: $small-container-sm;
  }

  &--no-padding {
    padding: 0;
  }
}

.container-width {
  width: $container-sm;

  &--small {
    width: $small-container-sm;
  }
}

.clearfix {
  @include clearfix();
}

.row {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: $container-sm;
}

[class*="col-"] {
  float: left;
  display: inline;
  min-height: 1px;
  margin: 0;
  padding: ($gutter-width / 2)
}

@mixin center-col {
  @include clearfix();
  display: block;
  float: none;
  clear: both;
  margin-left: auto !important;
  margin-right: auto !important;
}

.center-col {
  @include center-col;
}

.hidden {
  display: none !important;
}

@for $i from 1 through $columns {

  .col-xs-#{$i},
  .xs-#{$i} {
    $colWidth: 1% * ($column-width * $i);
    width: $colWidth;
  }
}

@media (min-width: $screen-sm) {

  .container,
  .container-width {
    max-width: calc(100% - 1rem);
  }

  @for $i from 1 through $columns {

    .col-sm-#{$i},
    .sm-#{$i} {
      $colWidth: 1% * ($column-width * $i);
      width: $colWidth;
    }
  }

  .hidden-sm-and-up {
    display: none !important;
  }
}

@media (min-width: $screen-md) {

  .container,
  .container-width {
    width: $container-md;
    max-width: calc(100% - 2rem);

    &--small {
      width: $small-container-md;
    }
  }

  .row {
    max-width: $container-md;
  }

  @for $i from 1 through $columns {

    .col-md-#{$i},
    .md-#{$i} {
      $colWidth: 1% * ($column-width * $i);
      width: $colWidth;
    }
  }

  .hidden-md-and-up {
    display: none !important;
  }
}

@media (min-width: $screen-lg) {

  .container,
  .container-width {
    width: $container-lg;

    &--small {
      width: $small-container-lg;
    }
  }

  .row {
    max-width: $container-lg;
  }

  @for $i from 1 through $columns {

    .col-lg-#{$i},
    .lg-#{$i} {
      $colWidth: 1% * ($column-width * $i);
      width: $colWidth;
    }
  }

  .hidden-lg-and-up {
    display: none !important;
  }
}

@media (min-width: $screen-xl) {

  .container,
  .container-width {
    width: $container-xl;

    &--small {
      width: $small-container-xl;
    }
  }

  .row {
    max-width: $container-xl;
  }

  @for $i from 1 through $columns {

    .col-xl-#{$i},
    .xl-#{$i} {
      $colWidth: 1% * ($column-width * $i);
      width: $colWidth;
    }
  }
}

@media (min-width: $screen-xxl) {

  .container,
  .container-width {
    width: $container-xxl;

    &--small {
      width: $small-container-xxl;
    }
  }

  .row {
    max-width: $container-xxl;
  }

  @for $i from 1 through $columns {

    .col-xxl-#{$i},
    .xxl-#{$i} {
      $colWidth: 1% * ($column-width * $i);
      width: $colWidth;
    }
  }

  .hidden-xxl {
    display: none !important;
  }

  .center-col-xxl {
    @include center-col;
  }
}

@media (max-width: $screen-xxl - (1/$base-font-size)) {
  .hidden-xl-and-down {
    display: none !important;
  }
}

@media (max-width: $screen-xl - (1/$base-font-size)) {
  .hidden-lg-and-down {
    display: none !important;
  }
}

@media (max-width: $screen-lg - (1/$base-font-size)) {
  .hidden-md-and-down {
    display: none !important;
  }
}

@media (max-width: $screen-md - (1/$base-font-size)) {
  .hidden-sm-and-down {
    display: none !important;
  }
}

@media (max-width: $screen-sm - (1/$base-font-size)) {
  .hidden-xs {
    display: none !important;
  }

  .center-col-xs {
    @include center-col;
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md - (1 / $base-font-size)) {
  .hidden-sm {
    display: none !important;
  }

  .center-col-sm {
    @include center-col;
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg - (1/$base-font-size)) {
  .hidden-md {
    display: none !important;
  }

  .center-col-md {
    @include center-col;
  }
}

@media (min-width: $screen-lg) and (max-width: $screen-xl - (1/$base-font-size)) {
  .hidden-lg {
    display: none !important;
  }

  .center-col-lg {
    @include center-col;
  }
}

@media (min-width: $screen-xl) and (max-width: $screen-xxl - (1/$base-font-size)) {
  .hidden-xl {
    display: none !important;
  }

  .center-col-xl {
    @include center-col;
  }
}
