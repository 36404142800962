// Button
//
// The .btn class can be used on anything, but generally used with a, button, and input tags
//
// Markup:
// <a class="btn {{modifier_class}}">Button Element</a>
// <button class="btn {{modifier_class}}">Button Element</button>
// <input class="btn {{modifier_class}}" type="button" value="Input:button" />
// <input class="btn {{modifier_class}}" type="submit" value="Input:submit" />
//
// Styleguide Components.Buttons

.btn {
  display: inline-block;
  font-size: 0.875rem;
  padding: $button-padding;
  border: none;
  box-shadow: inset 0 0 0 2px $brand-dark-grey;
  border-radius: 25px;
  color: $brand-white;
  background-color: $brand-primary;
  cursor: pointer;
  text-align: center;
  transition: background 500ms ease-in-out, color 500ms ease-in-out;

  & .btn__icon {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    max-width: 3rem;
    max-height: 3rem;
    margin: 0 auto;
    fill: $brand-white;
  }

  &:hover {
    color: $brand-white;
    background-color: lighten($brand-primary, 25%);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $brand-dark-grey;
  }

  // Button Skins
  //
  // These are the modifier classes to give the buttons a different look.  Sizes and colors can be changed.
  //
  // Markup:
  // <a class="btn {{modifier_class}}">Button Element</a>
  // <button class="btn {{modifier_class}}">Button Element</button>
  // <input class="btn {{modifier_class}}" type="button" value="Input:button" />
  // <input class="btn {{modifier_class}}" type="submit" value="Input:submit" />
  //
  // .btn--default - Default colored button.
  // .btn--primary - Primary color button.
  // .btn--danger - Danger button.
  // .btn--success - Success button.
  // .btn--warning - Warning button.
  // .btn--white - White colored button.
  // .btn--dark - Dark colored button.
  // .btn--outline - Button with an outline. Can be paired with any of the color modifiers as well.
  // .btn--small - Smaller padded button.
  //
  // Styleguide Components.Buttons.ButtonSkins
  &--default,
  &-default {
    background-color: $brand-secondary;

    &:hover {
      background-color: darken($brand-secondary, 15%);
    }

    &:focus {
      box-shadow: 0 0 0 2px darken($brand-secondary, 30%);
    }
  }

  &--primary,
  &-primary {
    background-color: $brand-primary;

    &:hover {
      background-color: lighten($brand-primary, 25%);
    }

    &:focus {
      box-shadow: 0 0 0 2px darken($brand-primary, 30%);
    }
  }

  &--warning,
  &-warning {
    background-color: $brand-warning;

    &:hover {
      background-color: darken($brand-warning, 25%);
    }

    &:focus {
      box-shadow: 0 0 0 2px darken($brand-warning, 30%);
    }
  }

  &--danger,
  &-danger {
    background-color: $brand-danger;

    &:hover {
      background-color: darken($brand-danger, 15%);
    }

    &:focus {
      box-shadow: 0 0 0 2px darken($brand-danger, 30%);
    }
  }

  &--success,
  &-success {
    background-color: $brand-success;

    &:hover {
      background-color: darken($brand-success, 15%);
    }

    &:focus {
      box-shadow: 0 0 0 2px darken($brand-success, 30%);
    }
  }

  &--white {
    color: $brand-dark-grey;
    background-color: $brand-white;

    &:hover {
      color: $brand-dark-grey;
      background-color: darken($brand-white, 15%);
    }
  }

  &--dark {
    background-color: $brand-dark-grey;

    &:hover {
      background-color: lighten($brand-dark-grey, 25%);
    }

    &:focus {
      outline-color: $brand-primary;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $brand-medium-grey;
  }

  &--squared {
    border-radius: 0;
  }

  &--outline {
    border: 0.125rem solid $brand-primary;
    padding: $button-outline-padding;
    box-shadow: none;

    &.btn {
      color: $brand-dark-grey;
      background-color: transparent;
      transition: border-color 500ms ease, color 500ms ease;

      &:hover {
        color: lighten($brand-dark-grey, 25%);
      }

      &--default,
      &-default {
        border-color: $brand-secondary;

        &:hover {
          border-color: darken($brand-secondary, 25%);
        }

        &:focus {
          border-color: $brand-primary;
          box-shadow: none;
        }
      }

      &--primary,
      &-primary {
        border-color: $brand-primary;

        &:hover {
          border-color: lighten($brand-primary, 25%);
        }

        &:focus {
          border-color: $brand-secondary;
          box-shadow: none;
        }
      }

      &--warning,
      &-warning {
        border-color: $brand-warning;

        &:hover {
          border-color: darken($brand-warning, 25%);
        }

        &:focus {
          border-color: $brand-primary;
          box-shadow: none;
        }
      }

      &--danger,
      &-danger {
        border-color: $brand-danger;

        &:hover {
          border-color: darken($brand-danger, 15%);
        }

        &:focus {
          border-color: $brand-primary;
          box-shadow: none;
        }
      }

      &--success,
      &-success {
        border-color: $brand-success;

        &:hover {
          border-color: darken($brand-success, 15%);
        }

        &:focus {
          border-color: $brand-primary;
          box-shadow: none;
        }
      }

      &--white {
        border-color: $brand-white;

        &:hover {
          border-color: darken($brand-white, 15%);
        }

        &:focus {
          border-color: $brand-primary;
          box-shadow: none;
        }
      }

      &--dark {
        border-color: $brand-dark-grey;

        &:hover {
          border-color: lighten($brand-dark-grey, 25%);
        }

        &:focus {
          border-color: $brand-primary;
          box-shadow: none;
        }
      }
    }
  }

  &--fill {
    width: 100%;
  }

  &--small {
    padding: 0.25em 0.375em;
  }

  &--no-click {
    pointer-events: none;
  }

  &--right {
    float: right;
  }

  & svg {
    display: inline-block;
    max-width: 1rem;
  }

  @media (min-width: $screen-md) {
    font-size: 0.875rem;
  }

  @media (min-width: $screen-lg) {
    font-size: 1rem;
  }
}

// Close Button
//
// Specialty button to indicate a popup close, modal close, etc.
//
// Markup:
// <a class="close"></a>
//
// Styleguide Components.Buttons.Close

.close {
  @extend .btn;
  @extend .btn--danger;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 1.1875rem;
    left: 0.625rem;
    width: 1.25rem;
    height: 0.125rem;
    background-color: $brand-white;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &--dynamic {
    height: 100%;
    width: 100%;

    &:after,
    &:before {
      top: 50%;
      left: 52%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

// Inline Button Container
//
// Container to create a group of inline buttons.  Buttons stack on mobile screens.
//
// Markup:
// <div class="inline-buttons"><a class="btn btn--primary">Inline Button</a><a class="btn btn--default">Inline Button</a><a class="btn btn--danger">Inline Button</a><a class="btn btn--dark">Inline Button</a></div>
//
// Styleguide Components.Buttons.InlineButtonContainer

.inline-buttons {
  display: flex;
  flex-wrap: wrap;

  & .btn {
    width: 100%;
    margin: 0 0 0.5rem;
  }

  @media (min-width: $screen-md) {
    flex-wrap: nowrap;

    & .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem 0.5rem;

      &:first-child {
        margin: 0 0.5rem 0.5rem 0;
      }

      &:last-child {
        margin: 0 0 0.5rem 0.5rem;
      }

      &:first-child:last-child {
        margin: 0 0 0.5rem;
      }
    }

    &--wrap-md {
      flex-wrap: wrap;

      & .btn {
        width: calc(50% - 0.5rem);
        margin: 0 0 0.5rem;

        &:nth-child(odd) {
          margin: 0 0.5rem 0.5rem 0;
        }

        &:nth-child(even) {
          margin: 0 0 0.5rem 0.5rem;
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    &--wrap-md {
      &.inline-buttons {
        flex-wrap: nowrap;

        & .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0.5rem 0.5rem;

          &:first-child {
            margin: 0 0.5rem 0.5rem 0;
          }

          &:last-child {
            margin: 0 0 0.5rem 0.5rem;
          }

          &:first-child:last-child {
            margin: 0 0 0.5rem;
          }
        }
      }
    }
  }
}

.return-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 0 1em;

  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    margin: 0 1rem 0 0;
    background-color: $brand-white;
    border: 0.25rem solid $brand-primary;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 500ms ease;

    &:before {
      content: '❮';
      line-height: 1;
      font-size: 1.25rem;
      margin: -0.125rem 0 0;
      color: $brand-secondary;
      transition: color 500ms ease;
    }
  }

  &__text {
    flex: 1 0 auto;
    display: inline-block;
    font-size: 1.375rem;
    color: $brand-primary;
    max-width: 80%;
    transition: color 500ms ease;
  }

  &:hover {
    & .return-button {
      &__button {
        border-color: $brand-secondary;

        &:before {
          color: $brand-primary;
        }
      }

      &__text {
        color: $brand-secondary;
      }
    }
  }

  @media (min-width: $screen-sm) {
    margin: 0 0 1.25em;

    &__text {
      font-size: 1.75rem;
    }
  }

  @media (min-width: $screen-md) {
    margin: 0 0 2em;

    &__button {
      width: 3rem;
      height: 3rem;
    }

    &__text {
      font-size: 2.25rem;
    }

    &--small {
      & .return-button {
        &__button {
          min-width: 1.5rem;
          width: 2rem;
          height: 2rem;
          border: 0.125rem solid $brand-primary;

          &:before {
            font-size: 0.875rem;
          }
        }

        &__text {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.forward-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin: 0 0 1em;

  &__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    margin: 0 0 0 1rem;
    background-color: $brand-white;
    border: 0.25rem solid $brand-primary;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 500ms ease;

    &:before {
      content: '❯';
      line-height: 1;
      font-size: 1.25rem;
      margin: -0.125rem 0 0 0.125rem;
      color: $brand-secondary;
      transition: color 500ms ease;
    }
  }

  &__text {
    flex: 1 0 auto;
    max-width: calc(100% - 3.5rem);
    display: inline-block;
    font-size: 1.375rem;
    color: $brand-primary;
    // max-width: 80%;
    text-align: right;
    transition: color 500ms ease;
  }

  &:hover {
    & .forward-button {
      &__button {
        border-color: $brand-secondary;

        &:before {
          color: $brand-primary;
        }
      }

      &__text {
        color: $brand-secondary;
      }
    }
  }

  @media (min-width: $screen-sm) {
    margin: 0 0 1.25em;

    &__text {
      font-size: 1.75rem;
    }
  }

  @media (min-width: $screen-md) {
    margin: 0 0 2em;

    &__button {
      width: 3rem;
      height: 3rem;
    }

    &__text {
      font-size: 2.25rem;
    }

    &--small {
      & .forward-button {
        &__button {
          min-width: 1.5rem;
          width: 2rem;
          height: 2rem;
          border: 0.125rem solid $brand-primary;

          &:before {
            font-size: 0.875rem;
          }
        }

        &__text {
          font-size: 1.5rem;
        }
      }
    }
  }
}

/* End Button Styles */
