/* ---------------------------------------------------------------- /
* Default theme for Certified Maintenance Program
*
* This file used the default variables found in the _variables.scss
* file. Overwrite them by importing variables of the same name
* above this file.
* -----------------------------------------------------------------*/

// Normalize CSS
@import 'utilities/normalize/normalize';

// Vendor CSS
@import 'vendor/font-awesome',
        'vendor/highcharts';

// Add in all variables
@import 'base/variables';

// Add in utilities
@import 'utilities/mixins';

// Add base styles
@import 'base/base',
        'base/fonts',
        'base/skin',
        'base/typography';

// Add layout styles
@import 'layout/grid',
        'layout/header',
        'layout/navigation',
        'layout/main',
        'layout/footer';

// Add component styles
@import 'components/cards',
        'components/forms',
        'components/icons',
        'components/modals',
        'components/panels',
        'components/print',
        'components/tables',
        'components/tabs',
        'components/toast',
        'components/utilities';

// Add global page styles
@import 'global-pages/dealers',
        'global-pages/error',
        'global-pages/login',
        'global-pages/noaccess',
        'global-pages/service',
        'global-pages/sitewide';

// Add shame file
@import 'shame';
