.prompt-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: transparentize($brand-dark-grey, 0.5);
  transition: opacity 500ms ease;

  &--open {
    opacity: 1;
  }
}

.prompt {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  min-height: 10.5rem;
  padding: 2rem 2.5rem 3rem;
  background: $brand-white;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.5);

  &__message {
    font-size: 1.5rem;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
    line-height: 0.85;
    font-weight: bold;
    text-align: center;
    color: $brand-danger;
    cursor: pointer;
    transition: color 500ms ease;

    &:hover {
      color: $brand-grey;
    }
  }

  &__action {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 700;
    color: $brand-secondary;
    cursor: pointer;
    transition: color 500ms ease;

    &:hover {
      text-decoration: underline;
      color: darken($brand-secondary, 15%);
    }
  }

  @media (min-width: $screen-sm) {
    width: 30rem;
    min-height: 12.5rem;
    padding: 1rem 2.5rem 3rem;

    &__message {
      font-size: 2rem;
    }
  }
}
