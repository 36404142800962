// - - - - - - - - - - - - - - - - - - -
// - - Main

#app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#dashboard {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 2rem 1fr 3.25rem;
  grid-template-rows: 2rem 1fr 3.25rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main'
    'sidenav';
  height: 100vh;
  max-width: 100%;
  max-height: 100vh;

  &.dev-site #main {
    background-image: url("https://images.maintenanceprogram.com/images/dev-server-background.jpeg");
    background-size: 20rem;
  }

  @media (min-width: $screen-sm) {
    -ms-grid-rows: 2rem 1fr;
    grid-template-rows: 2rem 1fr;
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'sidenav header'
      'sidenav main';
  }
}

#main {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: main;
  min-width: 0; // Don't fit width to contents (DO NOT overflow page width)
  background-color: $brand-light-blue-grey;

  @media (min-width: $screen-sm) {
    -ms-grid-column: 2;
  }
}

.section-container {
  @extend .container;
  background-color: $brand-white;

  @media (min-width: $screen-sm) {
    padding: 0.5rem 1.5rem;
  }

  &+.section-container {
    margin: 1em 0 0;
  }

  &--transparent {
    background-color: transparent;
  }

  @media (min-width: $screen-md) {
    min-height: 75vh;
    padding: 1rem 1.5rem;
  }

  @media (min-width: $screen-md) {
    padding: 1.5rem;
  }
}

.section {
  &__title {
    font-size: 1.75rem;
    font-weight: 400;
    width: 100%;
    margin: 0 0 0.25em;
    line-height: 1;
  }

  &__subtitle {
    font-size: 1.125rem;
    font-weight: 400;
    width: 100%;
    margin: 0 0 0.25em;
  }

  @media (min-width: $screen-md) {
    &__title {
      font-size: 2rem;
    }
  }
}

.section-header {
  &__title {
    margin: 0 0 0.25em;

    &+.section-header__subtitle {
      margin: -1.5em 0 0;
    }
  }

  &__subtitle {
    margin: 0 0 0.25em;
  }
}

#page-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  min-height: calc(100vh - 5.25rem);
  max-height: calc(100vh - 5.25rem);
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: $primary $medium-light-gray;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px
  }

  &::-webkit-scrollbar-track {
    background: $medium-light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brand-primary;
  }

  @media (min-width: $screen-sm) {
    min-height: calc(100vh - 2rem);
    max-height: calc(100vh - 2rem);
  }

  @media (min-width: $screen-md) {
    padding: 1rem 0 0;

    &::-webkit-scrollbar {
      width: 7px
    }
  }
}

.dashboard-title {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.25;
  margin: 1rem 0 0.5rem;

  @media (min-width: $screen-sm) {
    font-size: 2rem;
  }

  @media (min-width: $screen-md) {
    font-size: 2.5rem;
    margin: 0 0 0.125em;
  }

  @media (min-width: $screen-lg) {}
}
