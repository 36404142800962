.feedback-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 3.75rem;
  right: 0.75rem;
  z-index: 9999998;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  border-radius: 50%;
  border: none;
  background-color: $brand-primary;
  color: $brand-white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.15), 0 1px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 500ms ease;
  outline: none !important;

  & .fa {
    font-size: 1.625rem;
  }

  &:hover {
    background-color: $brand-secondary;
  }

  &.shepherd-modal-target {
  &.shepherd-enabled {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.15), 0 1px 5px rgba(0, 0, 0, 0.25) !important;
  }

  &:focus {
    outline: none;
  }
}

  @media (min-width: $screen-sm) {
    bottom: 0.75rem;
    right: 1rem;
  }

  @media (min-width: $screen-md) {
    bottom: 1rem;
  }
}

#feedbackModal {
  z-index: 9999999;
}
