// - - - - - - - - - - - - - - - - - - -
// - - skin
// global skin styles - gradients, colors, box-shadows, etc.

body {
  background-color: $brand-light-blue-grey;
  color: $base-font-color;
}

*,
*:after,
*:before {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $brand-dark-grey;
}

a {
  color: $link-color;
  transition: color 500ms;

  &:hover {
    color: lighten($link-color, 25%);
  }
}

// Inputs
//
// Base style for input tags
//
// Markup:
// <input type="text" placeholder="test"></input>
//
// Styleguide Base.Forms.Input

input[type=email],
input[type=password],
input[type=phone],
input[type=tel],
input[type=text],
select,
textarea {
  display: inline-block;
  background-color: $brand-white;
  color: $brand-dark-grey;
  border: $base-border;
  border-color: $brand-dark-grey;
  border-radius: $base-border-radius;
  padding: $base-padding;
  margin: 0;
  max-width: 100%;
  transition: background-color 500ms, border-color 500ms, color 500ms;

  &::-moz-focus-inner,
  &:focus {
    outline: none;
    background-color: $brand-white;
    border-color: $brand-primary;
    border-width: 2px;
    border-style: solid;
  }

  &:hover {
    outline: none;
  }
}

input:read-only {
  border-color: $brand-grey;
  color: $brand-grey;
  cursor: not-allowed;
}

textarea {
  resize: vertical;
  min-height: 10rem;
  margin: 0 0 0.75rem;
}

// Select
//
// Base style for select tags
//
// Markup:
// <select><option>Value 1</option><option>Value 2</option><option>Value 3</option></select>
//
// Styleguide Base.Forms.Select

select {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 2.5em;
  line-height: 1.15;
  background-image: url('https://images.maintenanceprogram.com/images/icons/arrow-down.png');
  background-size: 1em;
  background-position: right 0.5em center;
  background-repeat: no-repeat;

  &::-ms-expand {
    display: none;
  }
}

// Labels
//
// Base style for labels
//
// Markup:
// <label>This is an input label</label>
//
// Styleguide Base.Forms.Label

label {
  display: inline-block;
  margin: 0 0 0.5rem;
  font-weight: 500;
}

// Textarea
//
// Base style for textarea tags
//
// Markup:
// <textarea placeholder="Your long message goes here"></textarea>
//
// Styleguide Base.Forms.Textarea

textarea {
  width: 100%;
}

footer {}
