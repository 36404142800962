/* Sticky Header Styles */
.stick {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  margin: 0 auto;

  @media (min-width: $screen-md) {
    width: $container-md - 1rem;
  }

  @media (min-width: $screen-lg) {
    width: $container-lg - 1rem;
  }

  @media (min-width: $screen-xl) {
    width: $container-xl - 1rem;
  }

  @media (min-width: $screen-xxl) {
    width: $container-xxl - 1rem;
  }
}
/* End Sticky Header Styles */
