#no-access-title {
  width: 100%;
  margin: 0 0 0.5em;
}
#no-access-subtitle {
  width: 100%;
  font-weight: 300;
  line-height: 1.15;
  margin: 0 0 1em;
}
