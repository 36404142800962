/* Page Scroll Styles */
.back-to-top {
    display: none;
    position: fixed;
    right: -10rem;
    bottom: 1.5rem;
    z-index: 150;
    padding: 1rem;
    color: $brand-white;
    background-color: $brand-primary;
    text-align: center;
    border-radius: $base-border-radius;
    box-shadow: $base-box-shadow;
    cursor: pointer;
    opacity: 0;
    transition: opacity 500ms ease, right 100ms ease 500ms;

    &:hover {
      background-color: lighten($brand-primary, 25%);
    }

    &--active {
      opacity: 1;
      right: 2rem;
      transition: opacity 500ms ease;
    }

    @media (min-width: $screen-md) {
      display: block;
    }
}
/* End Page Scroll Styles */
