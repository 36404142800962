@import 'variables';
@import 'theme/theme-default';
@import 'pages/contracts',
'pages/index',
'pages/layout',
'pages/setup';

//         'pages/index',
// 'pages/noaccess',
// 'pages/registers';

.page {
  .expiration {
    width: auto;
    display: inline-block;
    padding: 5px 12px;
    border-radius: 3px;
    font-size: 75%;
    //border: 1px solid lighten($brand-danger, 30%);
    text-transform: uppercase;
    //color: lighten($text-color, 35%);
    position: absolute;
    right: 20px;
    margin-top: 10px;
    z-index: 50;

    // @media (min-width: $container-md) {
    //   top: 10px;
    // }
  }
}

#main-nav .navbar-nav {
  &>li {
    &>a {
      padding-left: 8px;
      padding-right: 8px;

      // @media (min-width: $container-x-large-desktop) {
      //   padding-left: 15px;
      //   padding-right: 15px;
      // }
    }
  }
}

#beta-prompt {
  margin-bottom: 80px;
}
