@mixin keyframes($animationName) {
   @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
     @content;
  }
  @-o-keyframes #{$animationName} {
     @content;
  }
  @keyframes #{$animationName} {
     @content;
  }
}
@mixin animation($property...) {
  -webkit-animation: $property;
  -moz-animation: $property;
  -ms-animation: $property;
  -o-animation: $property;
  animation: $property;
}
@include keyframes(blink) {
  0% {
    opacity: 0.25;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}
@include keyframes(spin-cw) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@include keyframes(spin-ccw) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
