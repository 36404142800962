.dealer-results {
  width: 100%;
  padding: 0.5rem 0.5rem 1rem;
  margin: 0.5rem 0 0;
  background-color: $brand-white;

  &>.table {
    margin: 0;
  }

  @media (min-width: $screen-md) {
    margin: 0;
    padding: 1rem 1rem 1.25rem;
  }
}

.dealer-search {
  width: 100%;
  position: relative;

  &__input,
  & input[type='text'],
  & input[type='search'] {
    width: 100%;
    background: none;
    border: 0;
    padding-left: 3rem;
    border-bottom: 2px solid $brand-primary;
    font-size: 1.25rem;

    &:focus {
      outline: none;
      border-color: $brand-secondary;
    }
  }

  &__search {
    position: absolute;
    top: -2px;
    left: 0;
    width: 3rem;
    height: 3rem;
    padding: 0;
    border: 0;
    background: none;

    &:focus {
      outline: none;
    }
  }
}

.dealer-list {
  &__pages {
    margin: auto auto 0;
  }
}

.search-icon {
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;

  & path {
    fill: none;
    stroke-width: 2px;
    stroke: $brand-dark-grey;
    stroke-linecap: round;
    vector-effect: non-scaling-stroke;
    transition: all 500ms linear;
  }

  & #left {
    stroke-dashoffset: 94;
    stroke-dasharray: 64;
  }

  & #right {
    stroke-dashoffset: 76;
    stroke-dasharray: 64;
  }

  &--close {

    & #left,
    & #right {
      stroke-dashoffset: 34;
      stroke-dasharray: 64;
    }
  }
}
