.page-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 45%;
  left: 51%;
  right: 51%;
  bottom: 57%;
  z-index: 999;
  border-radius: 50%;
  overflow: hidden;
  background-color: $brand-primary;
  transition: top 500ms ease, left 500ms ease, right 500ms ease, bottom 500ms ease, border-radius 300ms ease 300ms;

  &__close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    width: 4rem;
    height: 4rem;
    background-color: transparent;
    opacity: 0;
    transition: opacity 300ms ease;

    &:before,
    &:after {
      top: 1.9375rem;
      left: 0.75rem;
      width: 2.5rem;
      background-color: $brand-dark-grey;
    }

    &:hover {
      background-color: transparent;
      transform: scale(1.25);
    }

    &:focus {
      outline: none;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    min-height: 70vh;
    min-width: 75vw;
    max-width: 100vw;
    max-height: 80vh;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &--white {
    background-color: $brand-white;

    & .page-overlay {
      &__close {
        color: $brand-dark-grey;
      }
    }
  }

  &--grey {
    background-color: $brand-medium-grey;
  }

  &--charcoal {
    background-color: $brand-dark-grey;
  }

  &--open {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;

    & .page-overlay__close {
      opacity: 1;
      transition: transform 500ms cubic-bezier(0.75, -3.5, 0.3, 5.5), opacity 300ms ease 700ms;
    }
  }

  @media (min-width: $screen-sm) {
    &__close {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
    &__content {
      max-height: 100vh;
    }
  }
}
