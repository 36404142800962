#toast-container[class^='toast'] {
  & > .toast {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0;
    width: 100%;
    margin: 0.5rem auto 0;
  }
  @media (min-width: $screen-sm) {
    & > .toast {
      width: auto;
      max-width: 75%;
    }
  }
  @media (min-width: $screen-md) {
    & > .toast {
      max-width: 65%;
    }
  }
  @media (min-width: $screen-lg) {
    & > .toast {
      max-width: 50%;
    }
  }
}

.toast {
  border: 1px solid darken(desaturate($brand-primary, 15%), 5%);

  &-bottom-center {
    bottom: 0;

    @media (min-width: $screen-sm) {
      bottom: 0.5rem;
    }
  }

  &-close-button {
    order: 3;
    top: 0;
    right: 0;
  }

  &-progress {
    height: 2px;
  }

  &-title {
    order: 0;
    width: 100%;
  }

  &-message {
    order: 2;
    width: calc(100% - 1rem);
  }

  &-info {
    background-color: $brand-primary;
  }

  &-error {
    background-color: $brand-danger;
    border-color: darken(desaturate($brand-danger, 15%), 5%);
  }

  &-success {
    background-color: $brand-success;
    border-color: darken(desaturate($brand-success, 15%), 5%);
  }

  &-warning {
    background-color: $brand-warning;
    border-color: darken(desaturate($brand-warning, 15%), 5%);
  }
}
