/* Pagination Styles */
.pagination-container {
  width: 100%;
}

// Pagination
//
// Pagination styles
//
// Markup:
// <ul class="pagination"><li class="prev disabled"><a href="#">«</a></li><li class="active"><a>1</a></li><li><a href="#">2</a></li><li><a href="#">3</a></li><li><a href="#">4</a></li><li><a href="#">5</a></li><li><a href="#">6</a></li><li><a href="#">7</a></li><li><a href="#">8</a></li><li><a href="#">9</a></li><li><a href="#">10</a></li><li class="next"><a href="#">»</a></li></ul>
//
// Styleguide Components.Utilities.Pagination
.pagination {
  margin: 1.5em 0 0;
  list-style: none;
  display: flex;
  justify-content: center;

  & li {
    display: none;
    a {
      display: block;
      padding: 0.25em 0.5em;
      margin: 0 0.125em;
      border-radius: $base-border-radius;
      color: $brand-dark-grey;
      font-weight: 500;
      transition: background-color 500ms ease, color 500ms ease;

      &:hover {
        color: $brand-white;
        background-color: $brand-primary;
      }
    }

    &.disabled {

      & a {
        color: $brand-medium-grey;
        pointer-events: none;
      }
    }

    &.active {
      display: block;
      & a {
        color: $brand-white;
        background-color: $brand-primary;
        pointer-events: none;
      }
    }
  }

  & .prev {
    display: block;
    & a {
    }
  }

  & .next {
    display: block;
    & a {
    }
  }

  &--table {
    position: absolute;
    width: 100%;
    top: 100%;
  }

  @media (min-width: $screen-sm) {

    & li {
      display: block;
    }
  }
}
/* End Pagination Styles */

.table--pagination-container {
  position: relative;
  margin-bottom: 4.75rem;
}
