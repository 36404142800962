// Cards
//
// Default card, with title, subtitle and content
//
// Markup:
// <div class="card {{modifier_class}}"><h2 class="card__title">Card Title</h2><h4 class="card__subtitle">Card Subtitle</h4><div class="card__content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.<br/>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a.</div></div>
//
// .card--light-grey - Light grey colored card
// .card--medium-grey - Medium grey colored card
//
// Styleguide Components.Card

.card {
  padding: 0.5em;
  box-shadow: $base-box-shadow;
  border: 1px solid darken($brand-white, 15%);
  border-radius: 0;
  background-color: $brand-white;
  position: relative;

  &__alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 1.5rem;
    color: $brand-white;
    background-color: $brand-danger;
    font-size: 0.9375rem;
    display: flex;
    align-items: center;

    &__icon {
      margin: 0 1rem 0 0;
      width: 1.25rem;
      fill: $brand-white;
    }

    &+* {
      margin-top: 1.5rem;
    }

    @media (min-width: $screen-lg) {
      &~* {
        margin-top: 2rem;
      }
    }
  }

  &__title {
    margin: 0;
    line-height: 1;
  }

  &__subtitle {
    margin: 0 0 0.5em;
  }

  // Card with Edit
  //
  // Adding the .card__edit class to an element will give you a nice option for a button to edit the contents of the card.
  //
  // Markup:
  // <div class="card"><span class="card__edit"></span><h2 class="card__title">Card Title</h2><h4 class="card__subtitle">Card Subtitle</h4><div class="card__content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.<br/>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a.</div></div>
  //
  // Styleguide Components.Card.Edit
  &__edit {
    @extend .fa;
    position: absolute;
    top: 0.25em;
    right: 0.25em;
    width: 2em;
    height: 2em;
    padding: 0.4375em;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: $brand-medium-grey;
    text-align: center;
    cursor: pointer;
    transition: color 500ms ease;

    &:before {
      content: "\f142";
      font-size: 1.25rem;
    }

    &:hover {
      color: lighten($brand-primary, 25%);
    }
  }

  // Card Labels
  //
  // Labels are added using the .card__labels class
  //
  // Markup:
  // <div class="card"><span class="card__label {{modifier_class}}">Card Label</span><h2 class="card__title">Card Title</h2><h4 class="card__subtitle">Card Subtitle</h4><div class="card__content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.<br/>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a.</div></div>
  //
  // .label--dark - Use a dark color for the label
  // .label--danger - Use the danger color for the label
  // .label--light - Use a light color for the label
  // .label--secondary - Use the secondary color for the label
  // .label--success - Use the success color for the label
  // .label--warning - Use the warning color for the label
  //
  // Styleguide Components.Card.Labels
  &__labels {
    float: left;
    position: relative;
    top: -0.5em;
    left: -0.5em;
    width: 100%;

    &__item {
      @extend .label;
      border-radius: 0 !important;
      padding: 0.25rem 0.75rem;
      margin: 0 auto 0.25em 0;
      font-size: 0.75rem;
      width: calc(100% + 1rem);

      &~.card__title {
        margin: 1em 0 0;
      }
    }

    @media (min-width: $screen-sm) {
      top: -0.75em;
      left: -1em;

      &__item {
        width: auto;
        float: left;
        clear: left;
      }
    }

    @media (min-width: $screen-md) {
      top: -1em;
      left: -1.5em;
      width: auto;
      max-width: 75%;

      &__item {
        font-size: 0.875rem;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
      max-width: none;
    }
  }

  &__title {
    clear: left;

    &__edit {
      background: none;
      border: none;
      margin: 0 0 0 1rem;
      font-size: 0.75em;
      color: $brand-secondary;
      cursor: pointer;
      transition: color 400ms ease;

      &:hover {
        color: $brand-dark-grey;
      }
    }
  }

  &__badges {
    display: none;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    top: -0.25rem;
    right: -0.25rem;
    float: right;

    @media (min-width: $screen-md) {
      display: flex;
      top: -0.625rem;
      right: -0.625rem;
    }
  }

  &__badge {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    float: right;
    position: relative;
    max-width: 5.5em !important;
    padding: 0;
    font-size: 0.875em;
    text-align: center;
    border: 2px solid $brand-primary;
    margin: 0 0 0.25em;

    &__label {
      width: 100%;
      order: 0;
      background-color: $brand-primary;
      color: $brand-white;
      padding: 0.25em 0.5em;
      text-transform: capitalize;
    }

    &__value {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 2.75rem;
      order: 1;
      padding: 0.3125em 0.5em;
      text-align: center;
      text-transform: capitalize;
    }

    +.card__badge {
      clear: right;
      margin: 0 0 0.25em 1em;
    }

    &.cxt {
      &-promoter {
        border-color: $brand-success;

        & .card__badge__label {
          background-color: $brand-success;
        }
      }

      &-passive {
        border-color: $brand-warning;

        & .card__badge__label {
          background-color: $brand-warning;
        }
      }

      &-detractor {
        border-color: $brand-danger;

        & .card__badge__label {
          background-color: $brand-danger;
        }
      }

      &-other {
        border-color: $brand-secondary;

        & .card__badge__label {
          background-color: $brand-secondary;
        }
      }
    }

    &--small {
      // position: absolute;
      // top: 0.5rem !important;
      // right: 0.5rem !important;
      border-radius: 0.5rem;
      max-width: 3.125rem !important;

      & .card__badge {
        &__label {
          padding: 0.0625rem 0.125rem;
          font-size: 0.9375rem;
          font-weight: 500;
          text-transform: uppercase;
        }

        &__value {
          height: 2rem;
          padding: 1rem 0.5rem;
          font-size: 1.25rem;
          overflow: hidden;
        }
      }
    }

    &--dark {
      border-color: $brand-dark-grey;

      & .card__badge__label {
        background-color: $brand-dark-grey;
      }
    }

    @media (min-width: $screen-sm) {
      top: -0.5em;
      right: -0.5em;
      margin: 0 0 1em;

      +.card__badge {
        clear: none;
        margin: 0 0 1rem 0.25em;
      }
    }

    @media (min-width: $screen-md) {
      top: -1em;
      right: -1em;
    }
  }

  &__content {
    clear: both;
    width: 100%;
  }

  &__actions {
    display: flex;
    width: calc(100% + 1.125em);
    margin: 0 -0.5625em -0.5625em;

    & .btn {
      flex: 1;
      margin: 0;
    }
  }

  &__slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    &__item {
      position: absolute;
      top: 0;
      left: 101%;
      width: 100%;
      height: auto;
      transition: left 800ms ease;

      &--active {
        left: 0;
      }

      @media (min-width: $screen-md) {
        cursor: pointer;

        & .card__label {
          color: $brand-dark-grey;
          transition: color 500ms ease;
        }

        &:hover {
          & .card__label {
            color: $brand-secondary;
          }
        }
      }
    }
  }

  &__slider-navigation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1rem;
    text-align: center;

    &__button {
      display: inline-block;
      background: $brand-light-grey;
      border: none;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 0 0.125rem;
      padding: 0;
      cursor: pointer;

      &--active {
        background: $brand-secondary;
      }
    }
  }

  @media (min-width: $screen-sm) {
    padding: 1em;

    &__edit {
      top: 1em;
      right: 1em;
    }

    &__actions {
      width: calc(100% + 2.125em);
      margin: 0 -1.0625em -1.0625em;
    }
  }

  @media (min-width: $screen-md) {
    padding: 1.5em;

    &__actions {
      width: calc(100% + 3.125em);
      margin: 0 -1.5625em -1.5625em;
    }
  }

  &--light-grey {
    background-color: $brand-light-grey;
  }

  &--medium-grey {
    background-color: $brand-medium-grey;
  }

  &--info {
    padding: 2.5rem 0.75rem 3rem;
    max-height: 15rem;
    min-width: 18rem;

    & .card__label,
    & .card__sublabel,
    & .card__value,
    & .card__subvalue {
      width: 100%;
      margin: 0;
      text-align: center;
      font-weight: 300;
      line-height: 1.2;
    }

    & .card__value {
      margin: 0;
      font-size: 13.75vw;

      &--xs {
        font-size: 11.25vw;
      }

      &--sm {
        font-size: 11.25vw;
      }
    }

    & .card__subvalue {
      margin: 0 0 0.5rem;
    }

    & .card__label {
      font-size: 6.25vw;
    }

    & .card__sublabel {
      font-size: 4vw;
    }

    @media (min-width: $screen-sm) {
      & .card__value {
        margin: 1rem 0 0;
        font-size: 3.5rem;

        &--xs {
          margin: 2.25rem 0 0;
          font-size: 2.375rem;
        }

        &--sm {
          margin: 1.5rem 0 0;
          font-size: 3rem;
        }
      }

      & .card__label {
        font-size: 1.25rem;
      }

      & .card__sublabel {
        font-size: 1rem;
      }
    }

    @media (min-width: $screen-xl) {
      & .card__value {
        margin: 0.5rem 0 0;
        font-size: 4.25rem;

        &--xs {
          margin: 1.75rem 0 0;
          font-size: 2.875rem;
        }

        &--sm {
          margin: 1.75rem 0 0;
          font-size: 3.25rem;
        }
      }

      & .card__label {
        font-size: 1.75rem;
      }

      & .card__sublabel {
        font-size: 1.125rem;
      }
    }
  }

  &--dash {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border: 0;
    min-height: 43vw;

    &:after,
    &:before {
      border-radius: 0.25rem;
    }

    & img {
      flex-shrink: 0;
    }

    @media (min-width: $screen-sm) {
      min-height: 15rem;
    }
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  & .card {
    width: 100%;
    margin: 0 0 0.75rem;
  }

  @media (min-width: $screen-sm) {
    margin: 0 -0.5rem;

    & .card {
      width: calc(50% - 1rem);
      margin: 0.5rem;
    }
  }

  @media (min-width: $screen-md) {
    margin: 0 -1rem;

    & .card {
      width: calc(50% - 1.5rem);
      margin: 0.75rem;
    }
  }

  @media (min-width: $screen-lg) {
    margin: 0 -1rem;

    & .card {
      width: calc(33.33333% - 1.5rem);
      margin: 0.75rem;
    }
  }
}

.card-section-title {
  margin: 1em 0 0;
}

.action-cards-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 1em 0 0;
  width: 100%;

  @media (min-width: $screen-sm) {
    margin: 1em -0.25em 0;
    width: calc(100% + 0.5em);
  }

  @media (min-width: $screen-md) {
    margin: 1em -0.75em 0;
    width: calc(100% + 1.5em);
  }
}

.action-card {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 0.75em;
  padding-bottom: 2em;

  &__subtitle,
  &__title {
    font-weight: 300;
    line-height: 1;
  }

  &__title {
    max-width: 75%;
    font-size: 1.5em;
    font-weight: 400;
    margin: 0 0 1em;

    &+.action-card__subtitle {
      margin: -1.25em 0 1em;
    }
  }

  &__subtitle {
    margin: 0 0 1em;
    font-size: 1em;
  }

  &__status {
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 0.875rem;
    font-weight: 500;

    &--available {
      color: $brand-success;
    }

    &--goodwill {
      color: $brand-warning;
    }

    &--cancelled,
    &--expired {
      color: $brand-danger;
    }
  }

  &__value {
    font-size: 2.25em;
    font-weight: 300;
    width: 100%;
    text-align: center;
    margin-top: auto;

    &__label {
      display: block;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  &--available,
  &--goodwill {
    @extend .outline-cw;
    cursor: pointer;
  }

  &--goodwill {
    &:hover {

      &:after,
      &:before {
        border-color: $brand-warning !important;
      }
    }
  }

  &.action-card--available {
    &:after {
      bottom: -1px;
    }

    &:hover {

      &:after,
      &:before {
        width: calc(100% + 1px);
        height: calc(100% + 1px);
      }
    }
  }

  &--cancelled,
  &--expired {
    cursor: not-allowed;
  }

  @media (min-width: $screen-sm) {
    flex: 1 0 45%;
    margin: 0 0.25em 0.5em;
    max-width: calc(50% - 0.5em);

    &__title {
      font-size: 1.5em;
    }

    &__subtitle {
      font-size: 1em;
    }

    &__value {
      font-size: 2.25em;
    }
  }

  @media (min-width: $screen-md) {
    flex: 1 0 45%;
    margin: 0 0.75em 1.5em;
    max-width: calc(50% - 1.5em);

    &__title {
      font-size: 1.75em;
    }

    &__subtitle {
      font-size: 1.125em;
    }

    &__value {
      font-size: 2.75em;
    }
  }

  @media (min-width: $screen-lg) {
    flex: 1 0 30%;
    margin: 0 0.75em 1.5em;
    max-width: calc(33.3333% - 1.5em);
  }

  @media (min-width: $screen-xl) {
    flex: 1 0 20%;
    margin: 0 0.75em 1.5em;
    max-width: calc(25% - 1.5em);
  }
}
