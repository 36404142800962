.contracts-header {
  width: calc(100% + 1rem);
  background-color: $brand-white;
  margin: 0 -0.5rem;

  & .section-header {
    background-color: $brand-white;

    &.stick {
      padding: 0 0.5em;
      margin: 0 auto;
    }
  }
}

#contracts-delete {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1em 0;
}

#contracts-submit {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 1em 0;

  & .form-inline {
    width: 100%;
  }
}
