// - - - - - - - - - - - - - - - - - - -
// - - base
// theme defaults for base elements - h1-h6, p, a, etc.

body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

html {
  overflow-y: auto;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

*::-moz-focus-inner,
*:focus {
  outline: 2px solid $brand-primary;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  margin-left: 2em;
}

button,
input,
textarea {
  line-height: 1.3;

  &:disabled {
    background: #bbb !important;
    cursor: not-allowed !important;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 2px solid $brand-primary;
  }
}

img,
svg,
video {
  max-width: 100%;
  flex-shrink: 0;
}

table {
  width: 100%;
  font-size: 0.8125rem;
  background: $brand-white;
  border-collapse: collapse;
  box-shadow: $base-box-shadow;
  table-layout: fixed;

  & thead {
    background-color: $brand-primary;
    color: $brand-white;

    & th {
      padding: 0.5em;
      border: 2px solid $brand-primary;
      text-align: center;
      word-wrap: break-word;

      & a {
        color: $brand-white;
        display: block;

        &:hover {
          color: $brand-secondary;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        padding: 0.5em 0.125rem;
        border: 2px solid $brand-primary;
        text-align: center;
        word-wrap: break-word;
      }
    }
  }

  @media (min-width: $screen-lg) {
    font-size: 0.875rem;
  }

  @media (min-width: $screen-xl) {
    font-size: 0.9375rem;
  }
}
