/* Input Styles */
.form-section {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 0 1rem;
  overflow: hidden;
  transition: max-height 500ms ease;

  &__label {
    @extend .label;
    width: 100%;
    margin: 0.25em 0 0;

    @media (min-width: $screen-sm) {
      position: absolute;
      top: 0.75rem;
      right: 0;
      width: auto;
      margin: 0;
    }
  }

  &>.btn {
    margin: 0.5rem 0.5rem 0 auto;
  }

  &--hidden {
    max-height: 0;
  }

  &--visible {
    max-height: 40rem;
  }

  @media (min-width: $screen-md) {

    &--visible {
      max-height: 20rem;
    }

  }
}

.radio-group {
  & .radio-inline {
    display: inline-block;
    margin: 0.5rem 0 0.75rem;

    & .radio {
      display: flex;
      align-items: center;
      margin: 0 1rem 0 0;

      &__label {
        margin: 0;
      }
    }
  }
}

.form-input {
  margin: 0 0 1em;

  &__label {
    margin: 0 0 0.5em;
  }

  @media (min-width: $screen-sm) {
    margin: 0;
  }
}

.form-control {
  margin: 0 1em 0 0;
  width: 100%;

  &.form-control--highlight {
    border-color: $brand-warning;
  }
}

// Form Group - Base
//
// A group of labels, inputs and icons for forms that displays
// information on if the input is valid or not.
//
// Markup:
// <div class="form-group"><label for="input-id">Input Label</label><input id="input-id" name="input-id" type="text" class="form-control"></div>
//
// Styleguide Components.Forms.FormGroupBase

.form-group {

  & input,
  & select,
  & textarea {
    width: 100%;
    margin: 0;
  }

  label {
    &:not(.error) {
      display: block;
      margin: 0.5rem 0 0.25rem;
    }

    &.error {
      color: $brand-danger;
      display: block;
      margin: 0.25rem 0 0;
    }

    &.checkbox__label {
      margin: 0;
    }
  }

  & [class *='-icon'] {
    position: relative;

    & .fa {
      position: absolute;
      bottom: 0.75rem;
      right: 0.75rem;
      background-color: $brand-white;
    }
  }

  & textarea {
    padding: 1rem 1rem 2rem;
    min-height: 4.5rem;
    resize: vertical;

    &~.ember-view [class *='-icon'] {
      position: relative;

      & .fa {
        bottom: 1rem;
        left: 0.75rem;
      }
    }
  }

  &>.checkbox {
    margin: 1rem 0 0;
  }

  .input-icon {
    position: relative;
    margin: 0 !important;

    & input {
      padding-left: 2rem;
    }

    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 3rem;
      display: block;
      padding: 0.9375rem;
    }

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
    }

    &--price,
    &--search {

      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &--price {
      @extend .fa-usd;
    }

    &--search {
      @extend .fa-search;
    }

    &--password {
      & input {
        padding-left: 1rem;
        padding-right: 2rem;
      }
    }
  }

  // Form Group - Success
  //
  // A group of labels, inputs and icons for forms that displays
  // information on if the input is valid or not. This is an example
  // of it being valid.
  //
  // Markup:
  // <div class="form-group"><label for="input-id">Input Label</label><input id="input-id" name="input-id" type="text" class="form-control" aria-invalid="false"><div class="success-icon"><i id="ember2088" aria-hidden="true" class="ember-view fa fa-check"></i></div></div>
  //
  // Styleguide Components.Forms.FormGroupSuccess
  & .success-icon {
    color: $brand-success;
  }

  // Form Group - Error
  //
  // A group of labels, inputs and icons for forms that displays
  // information on if the input is valid or not. This is an example
  // of it being invalid.
  //
  // Markup:
  // <div class="form-group"><label for="input-id">Input Label</label><input id="input-id" name="input-id" type="text" class="form-control" aria-invalid="true"><div class="error-icon"><i id="ember2088" aria-hidden="true" class="ember-view fa fa-times"></i></div><label id="input-id-error" class="error" for="input-id">This is an error label</label></div>
  //
  // Styleguide Components.Forms.FormGroupError
  & .error-icon {
    color: $brand-danger;
  }
}

[aria-invalid='false'] {
  border-color: $brand-success !important;
  border-width: 2px;
}

[aria-invalid='true'] {
  border-color: $brand-danger !important;
  border-width: 2px;
}

// Inline Form
//
// A group of form elements that are inline.  Stacks
// on mobile screens.
//
// Markup:
// <div class="inline-form {{modifier_class}}"><input type="text" class="form-control" placeholder='input'/><input type="text" class="form-control" placeholder='Another input'/><button class='btn btn--dark'>Button</button></div>
//
// Styleguide Components.Forms.Inline Form

.form-inline,
.inline-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 0 1em;

  &>*,
  &>.input-group,
  &>button,
  &>input {
    width: 100%;
    margin: 0 0 0.5em;

    &>* {
      width: 100%;
    }
  }

  @media (min-width: $screen-sm) {
    flex-wrap: nowrap;

    &>*,
    &>.input-group,
    &>button,
    &>input {
      flex: 1 0 auto;
      width: auto;
      margin: 0 0.5rem;

      &:first-child {
        margin: 0 0.5rem 0 0;
      }

      &:last-child {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  &--right {
    justify-content: flex-end;

    &>*,
    &>button,
    &>input {
      flex: none;
      ;
    }
  }

  &--center {
    justify-content: center;
  }

  &--wrap-sm {
    @media (min-width: $screen-sm) {
      flex-wrap: wrap;

      &>*,
      &>.input-group,
      &>button,
      &>input {
        flex: none;
        ;
        width: 100%;
        margin: 0 0 0.5em;

        &:first-child {
          margin: 0 0 0.5rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    @media (min-width: $screen-md) {
      flex-wrap: nowrap;

      &>*,
      &>.input-group,
      &>button,
      &>input {
        flex: 1 0 auto;
        width: auto;
        margin: 0 1rem 0 0;
      }

      &>* {
        margin: 0 0.5rem;

        &:first-child {
          margin: 0 0.5rem 0 0;
        }

        &:last-child {
          margin: 0 0 0 0.5rem;
        }
      }
    }
  }

  &--wrap-md {
    @media (min-width: $screen-sm) {
      flex-wrap: wrap;

      &>* {
        flex: none;
        ;
        width: 100%;
        margin: 0 0 0.5em;

        &:first-child {
          margin: 0 0 0.5rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    @media (min-width: $screen-lg) {
      flex-wrap: nowrap;

      &>* {
        flex: 1 0 auto;
        width: auto;
        margin: 0 1rem 0 0;
      }

      &>* {
        margin: 0 0.5rem;

        &:first-child {
          margin: 0 0.5rem 0 0;
        }

        &:last-child {
          margin: 0 0 0 0.5rem;
        }
      }
    }
  }
}

.input-group {
  margin: 0 1em 0 0;

  &--icon {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5625em 1em;
    cursor: pointer;
    transition: background-color 500ms ease;
  }
}

.select-wrapper {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 2em;
  }
}

.editable-input {
  position: relative;
  border-radius: $base-border-radius;
  width: 100%;

  &__input {
    font-size: 0.875rem;
    padding: 0.6875rem 3rem !important;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__close,
  &__save {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 2.5rem;
    height: 100%;
    cursor: pointer;
    color: $brand-white;
    border: none;
  }

  &__close {
    left: 0;
    border-top-left-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
    background-color: $brand-danger;

    &:focus,
    &:hover {
      background-color: darken($brand-danger, 25%);
      outline: none;
    }
  }

  &__save {
    right: 0;
    border-top-right-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
    background-color: $brand-success;

    &:focus,
    &:hover {
      background-color: darken($brand-success, 25%);
      outline: none;
    }
  }

  & label {
    position: absolute;
    left: 2.5rem;
    bottom: -2em;
    font-size: 0.875rem;
  }
}

.editable-property {
  display: flex;
  align-items: center;

  & .btn {
    width: 100%;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;

    & svg {
      fill: $brand-dark-grey;
      float: left;
      margin: 0.125rem 0.5rem 0 0;
      max-height: 0.125rem;
    }
  }

  &__toggle {
    width: 3.5em !important;
    border-right: 0;

    +.btn {
      width: calc(100% - 3.5em);
    }
  }

  @media (min-width: $screen-sm) {
    & .btn {
      font-size: 0.875rem;
    }
  }

  @media (min-width: $screen-md) {
    & .btn {
      font-size: 1rem;
    }
  }

  @media (min-width: $screen-lg) {
    transition: flex 500ms linear;
    width: 1rem;

    &--editing,
    &:hover {
      flex: 5 0 auto
    }
  }

  @media (min-width: $screen-xl) {

    &--editing,
    &:hover {
      flex: 2 0 auto;
    }
  }
}

.per-page-container {
  text-align: right;

  &>label {
    display: block;
    width: 100%;
  }
}

.text-count {
  position: relative;

  &__count {
    position: absolute;
    right: 0.75rem;
    bottom: 1.75rem;
    z-index: 15;
    font-size: 0.9375rem;
  }

  &__error {
    position: absolute;
    left: 0.125rem;
    right: 0.125rem;
    bottom: 1.375rem;
    height: 3.5rem;
    z-index: 10;
    font-size: 0.9375rem;
    padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    color: $brand-white;
    background-color: $brand-danger;

    &~textarea {
      padding-bottom: 4rem;
    }

    &~.text-count__count {
      color: $brand-white;
    }
  }
}

.value-pair {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 0 1rem;

  &__key {
    font-weight: 500;
  }

  &__key,
  &__value,
  input {
    font-size: 0.875rem;
    width: 100%;
    overflow: hidden;
  }

  @media (min-width: $screen-sm) {
    flex-wrap: nowrap;
    margin: 0 0 0.5rem;

    &__key {
      width: 5rem;
    }

    &__value,
    input {
      width: auto;
      flex: 1 0 calc(100% - 5rem);
    }
  }

  @media (min-width: $screen-md) {
    &__key {
      width: 5rem;
    }

    &__value,
    input {
      width: auto;
      flex: 1;
    }

    &__key,
    &__value,
    input {
      font-size: 1rem;
    }
  }
}

.inline-search {
  display: inline-block;
  width: 100%;
  position: relative;

  & i {
    position: absolute;
    top: 0;
    height: 2rem;
    line-height: 2rem;
    z-index: 10;

    &.fa-search {
      left: 0.25rem;
    }

    &.fa-close {
      right: 0.25rem;
      cursor: pointer;
      transition: color 500ms ease;

      &:hover {
        color: $brand-secondary;
      }
    }
  }

  & input {
    background: transparent;
    border: none;
    border-bottom: 2px solid $brand-dark-grey;
    width: calc(100% - 1.25rem);
    padding: 0.5em 1.75em;

    &:focus {
      outline: none;
      border-color: $brand-secondary;
    }
  }

  @media (min-width: $screen-sm) {
    width: auto;

    & input {
      width: auto;
      min-width: 20rem;
    }
  }
}

.toggleable-select {
  width: calc(100% - 2rem);

  &+.close {
    width: 2rem;
    height: 100%;

    &:after,
    &:before {
      top: 50%;
      left: 52%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

/* End Input Styles */
