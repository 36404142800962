.minimal-list {
  list-style: none;
  min-width: 100%;
  margin: 0;
  padding: 1.5rem 0;

  &__item {
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid $brand-dark-grey;
    cursor: pointer;
    transition: all 300ms ease;

    &:last-child {
      border-bottom: 0;
    }

    &:hover,
    &:focus {
      outline: none;
      color: $brand-secondary;
      transform: scale(1.025);
    }
  }
}
