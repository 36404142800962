@import '../../vendor/bootstrap-datepicker';

.datetime-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & .date-picker,
  & .time-picker {
    margin: 2rem auto;
    @media (min-width: $screen-sm) {
      margin: 3rem auto;
    }
    @media (min-width: $screen-md) {
      margin: 0 0 2rem;
    }
  }
  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.date-picker {
  width: 18rem;

  & *:focus,
  & *:hover {
    outline: none !important;
  }

  &__header,
  &__table {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    & span {
      cursor: default;
      font-size: 1.125rem;
      font-weight: 500;
    }

    &__next,
    &__prev {
      display: flex;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      border: 0;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 500ms ease;

      &:before {
        display: block;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        line-height: 1.5rem;
        text-align: center;
      }

      &:hover {
        background-color: $brand-primary;

        &:before {
          color: $brand-white;
        }
      }
    }

    &__prev {
      &:before {
        content: '‹';
      }
    }

    &__next {
      &:before {
        content: '›';
      }
    }
  }

  &__table {
    & tr {
      & td,
      & th {
        width: 14.25%;
        padding: 0.75rem 0;
        font-size: 1.125rem;
        text-align: center;
        box-sizing: border-box;
        cursor: default;
      }

      & td {
        position: relative;

        & button {
          width: 2rem;
          height: 2rem;
          background: none;
          border: none;
          -webkit-appearance: none;
          appearance: none;
          line-height: 1;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0);
          transition: background-color 500ms ease, color 500ms ease;
        }

        &.clickable:hover,
        &.selected {
          cursor: pointer;

          & button {
            color: $brand-white;
            cursor: pointer;
            background-color: $brand-secondary;
          }
        }
      }
    }
  }
  @media (min-width: $screen-sm) {
    width: 19rem;
  }
}

.time-picker {
  width: 19rem;

  & *:focus,
  & *:hover {
    outline: none !important;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 2rem);
    margin: 0 1rem;

    &__option {
      width: 3rem;
      height: 3rem;
      margin: -1.5rem 0 0;
      position: relative;
      z-index: 10;
      font-size: 1.5rem;
      background-color: transparent;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 500ms ease, color 500ms ease;

      &--selected,
      &:hover {
        color: $brand-white;
        background-color: $brand-secondary;
      }
    }
  }

  &__clock {
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
    border: 3px solid $brand-primary;
    border-radius: 50%;
    position: relative;

    &__current {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.75rem;
      transform: translate(-50%, -50%);

      & button {
        display: inline-block;
        padding: 0.125rem 0.25rem;
        border: 0;
        font-weight: 100;
        background-color: transparent;
        transition: background-color 500ms ease, color 500ms ease;

        &:hover {
          cursor: pointer;
          color: $brand-white;
          background-color: $brand-secondary;
        }
      }

      & span {
        display: inline-block;
      }
    }

    &__hours,
    &__minutes {
      display: none;
      width: 100%;
      height: 100%;
      position: relative;

      &--active {
        display: block;
      }

      & button {
        position: absolute;
        width: 2rem;
        height: 2rem;
        padding: 0;
        font-size: 1.25rem;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0);
        -webkit-appearance: none;
        appearance: none;
        transition: background-color 500ms ease, color 500ms ease;

        &.selected,
        &:hover {
          color: $brand-white;
          background-color: $brand-secondary;
          cursor: pointer;
        }
      }
    }

    &__hours {
      &__1 {
        top: 1.25rem;
        left: 9.25rem;
      }

      &__2 {
        top: 3.75rem;
        left: 11.25rem;
      }

      &__3 {
        top: 6.5rem;
        left: 12.25rem;
      }

      &__4 {
        top: 9.25rem;
        left: 11.25rem;
      }

      &__5 {
        top: 11.5rem;
        left: 9.25rem;
      }

      &__6 {
        top: 12.25rem;
        left: 6.375rem;
      }

      &__7 {
        top: 11.5rem;
        left: 3.5rem;
      }

      &__8 {
        top: 9.25rem;
        left: 1.5rem;
      }

      &__9 {
        top: 6.5rem;
        left: 0.5rem;
      }

      &__10 {
        top: 3.75rem;
        left: 1.5rem;
      }

      &__11 {
        top: 1.25rem;
        left: 3.5rem;
      }

      &__12 {
        top: 0.5rem;
        left: 6.375rem;
      }
    }

    &__minutes {
      &__5 {
        top: 1.25rem;
        left: 9.25rem;
      }

      &__10 {
        top: 3.75rem;
        left: 11.25rem;
      }

      &__15 {
        top: 6.5rem;
        left: 12.25rem;
      }

      &__20 {
        top: 9.25rem;
        left: 11.25rem;
      }

      &__25 {
        top: 11.5rem;
        left: 9.25rem;
      }

      &__30 {
        top: 12.25rem;
        left: 6.375rem;
      }

      &__35 {
        top: 11.5rem;
        left: 3.5rem;
      }

      &__40 {
        top: 9.25rem;
        left: 1.5rem;
      }

      &__45 {
        top: 6.5rem;
        left: 0.5rem;
      }

      &__50 {
        top: 3.75rem;
        left: 1.5rem;
      }

      &__55 {
        top: 1.25rem;
        left: 3.5rem;
      }

      &__0 {
        top: 0.5rem;
        left: 6.375rem;
      }
    }
  }
}

.inline-date-select-container {
  position: relative;
  display: inline-block;
  margin: 0 0.125rem 0 0;
  cursor: pointer;
  border-bottom: 2px solid $brand-secondary;
  transition: background 500ms ease;

  &:hover {
    background: $brand-secondary;
    color: $brand-dark-grey;
  }
}

.inline-date-select {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  width: 20rem;
  z-index: 100;
  background: $brand-white;
  border: 1px solid $brand-medium-grey;
  box-shadow: 1px 1px 7px transparentize($brand-dark-grey, 0.4);
  padding: 0.5rem 1rem;
  font-size: 1rem;

  &--pages {
    overflow: hidden;
    padding: 0.5rem 2rem;
  }

  &__item {
    flex: 1 0 33%;
    max-width: calc(33.33333% - 0.5rem);
    text-align: center;
    cursor: pointer;
    margin: 0.25rem;
    background: $brand-white;
    transition: background 500ms ease;

    &:hover {
      background: $brand-light-grey;
    }

    &--active {
      color: $brand-secondary;
      cursor: default;

      &:hover {
        background: $brand-white;
      }
    }
  }

  &__next,
  &__prev {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.75rem;
    bottom: 0;
    z-index: 10;
    width: 2rem;
    font-size: 2.5rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: background 500ms ease;

    &:hover {
      background-color: $brand-light-grey;
    }
  }

  &__next {
    left: 0;

    &:before {
      content: '‹';
    }
  }

  &__prev {
    right: 0;

    &:before {
      content: '›';
    }
  }
}
