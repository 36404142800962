// Checkbox
//
// Markup style for specific checkbox styles
//
// Markup:
// <div class="checkbox {{modifier_class}}"><input type="checkbox" id="checkbox-id" class="checkbox__input" /><label class="checkbox__label" for="checkbox-id">Checkbox Label</label><div class="check"></div></div>
//
// .checkbox--alone - Hides the checkbox label
// .checkbox--inverted - Swaps positions of the checkbox label and input
// .checkbox--vertical - Sets the checkbox label above the input
//
// Styleguide Components.Forms.Checkbox
.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 0.75em 1em 0;

  & input[type=checkbox] {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    z-index: 1;
    height: 1rem;
    width: 1rem;
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked {
      &~.check {
        &::before {
          background-color: $brand-dark-grey;
        }
      }

      &:hover {
        &~.check {
          &::before {
            background-color: $brand-medium-grey;
          }
        }
      }
    }

  }

  & label {
    padding: 0 0 0 0.5em;
    font-weight: 400;
  }

  &__input {
    &:checked {
      &~.check {
        &::before {
          background-color: $brand-grey;
        }
      }
    }

    &:hover {
      &~.check {
        &::before {
          background-color: $brand-medium-grey;
        }
      }
    }

    &:focus {
      &~.check {
        outline: 2px solid $brand-primary;
      }
    }

    &:disabled {
      &:hover {
        &~.check {
          &::before {
            background: $brand-light-grey;
          }
        }
      }
    }
  }

  &__label {
    font-weight: 400;
  }

  & .check {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border: 2px solid $brand-dark-grey;
    background-color: $brand-light-grey;
    cursor: pointer;

    &::before {
      content: " ";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border: 1px solid $brand-light-grey;
      background-color: $brand-light-grey;
      cursor: pointer;
    }
  }

  &--alone {
    margin: 0;

    & .checkbox__label {
      display: none;
    }
  }

  &--inverted {
    & .checkbox__input {
      order: 2;
    }

    & label {
      padding: 0 0.5em 0 0;
    }

    & .check {
      left: initial;
      right: 0;
    }
  }

  &--vertical {
    flex-direction: column;
    justify-content: center;
    margin: 0;

    & .checkbox__input {
      order: 2;
    }

    & .checkbox__label {
      padding: 0 0 0.25em;
      margin: 0;
    }

    & .check {
      top: initial;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }

  @media (min-width: $screen-md) {

    & input[type=checkbox] {
      height: 1.125rem;
      width: 1.125rem;
    }

    & .check {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  @media (min-width: $screen-lg) {

    & input[type=checkbox] {
      height: 1.25rem;
      width: 1.25rem;
    }

    & .check {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

/* End Checkbox Styles */
