.animated-item {
  overflow: hidden;

  &--slide-vertical {
    max-height: 0;
    transition: max-height 500ms ease;

    &.animated-item--open {
      max-height: 10em;
    }
  }
}

.blink {
  @include animation(blink 2s infinite);
}

.spin-right {
  @include animation(spin-cw 1.5s infinite linear);
}

.spin-left {
  @include animation(spin-ccw 1.5s infinite linear);
}
// Loading Animation
//
// An animated loading icon. Size and speed modifiers can be combined.
//
// Markup:
// <div class="loading {{modifier_class}}"></div>
//
// .loading--small - Smaller loading icon
// .loading--large - Larger loading icon
// .loading--faster - Faster loading icon
// .loading--slower - Slower loading icon
//
// Styleguide Components.Utilities.AnimationLoading

.loading {
  &:before {
    content: '';
    display: block;
    @include animation(spin-cw 1s infinite linear);
    border: 5px solid $brand-medium-grey;
    border-top: 5px solid $brand-primary;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    margin: 0.5rem auto;
    pointer-events: none;
  }

  &--small {
    &:before {
      border-width: 2px;
      border-top-width: 2px;
      width: 2rem;
      height: 2rem;
    }
  }

  &--large {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before {
      border-width: 8px;
      border-top-width: 8px;
      width: 8rem;
      height: 8rem;
    }

    &:after {
      content: "Loading...";
      font-weight: 500;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // @include animation(spin-ccw 1s infinite linear);
    }
  }

  &--faster {
    &:before {
      @include animation(spin-cw 500ms infinite linear);
    }
  }

  &--slower {
    &:before {
      @include animation(spin-cw 2s infinite linear);
    }
  }
}

.loading-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: $brand-light-grey;
  box-shadow: $base-box-shadow;
}

.loading-container {
  justify-content: center;
}
