.tabs {
  display: flex;
  align-items: flex-end;
  list-style: none;
  margin: 0;
padding: 0;  

  & li {
    & a {
      @extend .underline-bottom;
      display: block;
      padding: 0.5em 0;
      margin: 0 1em;
      color: $brand-dark-grey;
      text-align: center;

      &:hover {
    color: lighten($brand-primary, 15%);
      }

      &.active {
        &:after {
          left: 0;
          right: 0;
        }
      }

      &:focus {
        outline: none;

        &:after {
          background-color: $brand-success;
        }
      }
    }
  }
}
