// Radio Buttons
//
// Markup style for specific checkbox styles
//
// Markup:
// <div class="radio {{modifier_class}}"><input type="radio" class="radio__input" id="radio-input-1"/><label class="radio__label" for="radio-input-1">Radio Option 1</label><div class="check"></div></div><div class="radio {{modifier_class}}"><input type="radio" class="radio__input" id="radio-input-2"/><label class="radio__label" for="radio-input-2">Radio Option 2</label><div class="check"></div></div>
//
// .radio--inline - Sets the radio labels beside the button
//
// Styleguide Components.Forms.Radio
.radio {
  position: relative;

  &--inline {
    display: flex;
    align-items: center;
    margin: 0 0.75em 0.5em 0;
  }

  & input[type=radio] {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    z-index: 1;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0;
    opacity: 0;
  }

  & label {
    font-weight: 400;
    padding: 0 0 0 0.5em;
  }

  &__input {
    &:hover {
      & ~ .check {
        &::before {
          background-color: $brand-medium-grey;
        }
      }
    }

    &:checked {
      & ~ .check {
        &::before {
          background-color: $brand-grey;
        }
      }
    }
  }

  &__label {
    font-weight: 400;
  }

  & .check {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $brand-grey;
    background-color: $brand-light-grey;

    &::before {
      content: " ";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 50%;
      border: 1px solid $brand-light-grey;
      background-color: $brand-light-grey;
      cursor: pointer;
    }
  }
}
/* End Radio Button Styles */
