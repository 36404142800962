// Alerts
//
// An alert bar for displaying important information. Spans the entire width of it's container.
//
// Markup:
// <div class="alert {{modifier_class}}">Important information or calls to action go here.</div>
//
// .alert--warning - Warning colored alert
// .alert--success - Success colored alert
// .alert--small - Alert with smaller font
//
// Styleguide Components.Utilities.Alert
.alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: $base-padding;
  font-size: 1rem;
  text-align: center;
  color: $brand-white;
  background-color: $brand-danger;

  &__icon {
    width: 1.5rem;
    height: 2rem;
    margin: 0 1rem 0 0;
  }

  &--left-align {
    text-align: left;
  }

  &--warning {
    background-color: $brand-warning;

    & a {
      color: $brand-dark-grey;

      &:hover {
        color: $brand-white;
      }
    }
  }

  &--secondary {
    background-color: $brand-secondary;

    & a {
      color: $brand-dark-grey;

      &:hover {
        color: $brand-white;
      }
    }
  }

  &--primary {
    background-color: $brand-primary;

    & a {
      color: $brand-dark-grey;

      &:hover {
        color: $brand-white;
      }
    }
  }

  &--success {
    background-color: $brand-success;
  }

  &--grey,
  &--gray {
    background-color: $brand-grey;

    & a {
      color: $brand-secondary;

      &:hover {
        color: $brand-white;
      }
    }
  }

  &--white {
    color: $brand-dark-grey;
    background-color: $brand-white;

    & a {
      color: $brand-secondary;

      &:hover {
        color: $brand-dark-grey;
      }
    }
  }

  &--dark {
    background-color: $brand-dark-grey;

    & a {
      color: $brand-secondary;

      &:hover {
        color: $brand-white;
      }
    }
  }

  &--small {
    font-size: 0.875em;
  }

  & .btn {
    margin: 0.25em 0.5em;
    font-size: 0.875rem;
  }

  &--outline {
    color: $brand-dark-grey;
    background-color: transparent;
    border: 2px solid $brand-danger;

    &.alert {

      &--warning {
        border-color: $brand-warning;
      }

      &--success {
        border-color: $brand-success;
      }
    }
  }

  @media (min-width: $screen-sm) {
    margin: 0 0 1em;
  }

  &--action {
    cursor: pointer;
    transition: background-color 400ms ease;

    &:hover {
      &.alert {
        &--warning {
          background-color: lighten($brand-warning, 10%);
        }
      }
    }
  }
}
