.inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
  }

  & > * {
    margin: 0 1rem 0 0;

    &:last-child {
      margin: 0;
    }
  }
}

.align-right {
  margin-left: auto;

  @media (min-width: $screen-md) {
    padding: 0 0 0 0.5em;
  }
}

.invisible {
  opacity: 0;
  visibility: hidden;
  cursor: default;
  pointer-events: none;
}
