// Brand Colors
//
// Colors we use for branding.  Variable names and hex values.
//
// $brand-primary - #294E6C, Default Primary Color
// $brand-secondary - #f77f64, Default Secondary Color
// $brand-warning - #e88a5c, Default Warning Color
// $brand-danger - #c24e4e, Default Danger Color
// $brand-success - #7da483, Default Success Color
// $brand-white - #fff, Default white Color
// $brand-light-grey - #ebebeb, Default light Color
// $brand-medium-grey - #bbb, Default medium color
// $brand-grey - #777, Default medium/dark color
// $brand-dark-grey - #404040, Default Dark Color
//
// Styleguide Base.Settings.Colors

// Colors
$brand-primary: #294E6C !default;
$brand-secondary: #f77f64 !default;
$brand-warning: #e88a5c !default;
$brand-danger: #d14949 !default;
$brand-success: #7aaf82 !default;

$brand-white: #fff;
$brand-light-grey: #ebebeb !default;
$brand-light-blue-grey: #edf0f3 !default;
$brand-medium-grey: #bbb !default;
$brand-grey: #606060 !default;
$brand-dark-grey: #404040 !default;

// New layout colors
$primary: #889cae !default;
$secondary: #f4825f !default;
$white: #fff !default;
$light-gray: #eceff4 !default;
$medium-light-gray: #ced1d5 !default;
$medium-gray: #aeb1b5 !default;
$medium-dark-gray: #8e9195 !default;
$charcoal: #404040 !default;


$link-color: $brand-secondary !default;

// Brand fonts
//
// The font for these applications is Open Sans
//
// Markup:
// <h1>Header Font - Bolder weight - varying size</h1>
// <div>Body font - Normal weight - 16px</div>
//
// Styleguide Base.Settings.Font
$base-font-size: 16 !default;
$base-font-color: $brand-dark-grey !default;

$body-font: 'Poppins', sans-serif !default;
$header-font: 'Poppins', sans-serif !default;

$body-font-weight: 400 !default;
$header-font-weight: 400 !default;

// Base properties
$base-border-radius: 0 !default;
$base-border: 2px solid $brand-grey !default;
$base-padding: 0.5em 1em !default;
$base-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1) !default;

$button-padding: 0.5em 1em !default;
$button-outline-padding: 0.375em 1em !default;
$input-padding: $base-padding !default;
$table-cell-padding: 0.75em !default;

// Grid Variables
$columns: 24 !default;
$column-width: 100 / $columns;

$gutter-width: 1em !default;

$container-xxl: 125rem !default;
$container-xl: 100rem !default;
$container-lg: 70rem !default;
$container-md: 56.5rem !default;
$container-sm: 100% !default;

$small-container-xxl: 100rem !default;
$small-container-xl: 80rem !default;
$small-container-lg: 60rem !default;
$small-container-md: 40rem !default;
$small-container-sm: 100% !default;

$screen-xxl: 150em !default;
$screen-xl: 112.5em !default;
$screen-lg: 80em !default;
$screen-md: 62.5em !default;
$screen-sm: 37.5em !default;
$screen-xs: 20em !default;
