.tooltip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  opacity: 0;
  transition: opacity 300ms ease;

  &--open {
    opacity: 1;
  }

  &__items {
    position: absolute;
    margin: 0;
    padding: 0.5em 1em;
    font-size: 0.875rem;
    list-style: none;
    background-color: $brand-white;
    border: 1px solid $light-gray;
    border-radius: 0.25rem;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.125);
    transition: left 500ms ease, top 500ms ease;

    & li {
      cursor: pointer;
      padding: 0.5rem 0;
      color: $brand-dark-grey;
      transition: color 400ms ease;

      & a {
        color: $brand-dark-grey;
      }

      &:hover {
        color: $brand-secondary;

        & a {
          color: $brand-secondary;
        }
      }
    }
  }
}
