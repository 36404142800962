// Tables
//
// Tables and markup
//
// Markup:
// <div class="table xs-24"><div class="table__row table__row--header"><div class="table__row__cell table__row__cell--sortable xs-8"><a><i aria-hidden="true" class="fa--left fa fa-chevron-down"></i>Column 1</a></div><div class="table__row__cell table__row__cell--sortable xs-8"><a>Column 2</a></div><div class="table__row__cell table__row__cell--sortable xs-8"><a>Column 3</a></div></div><div class="table__row"><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div></div><div class="table__row"><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div></div></div></div>
//
// Styleguide Components.Tables

.table {
  display: flex;
  flex-wrap: wrap;
  box-shadow: $base-box-shadow;
  margin: 1em 0 0;
  width: 100%;

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    background-color: $brand-white;
    border: none;
    padding: 0;
    position: relative;
    overflow: visible;

    &:nth-child(2) {
      & .table__row__cell {
        border-top: 0;
      }
    }

    &:nth-child(even) {
      background-color: $brand-light-grey;
    }

    &:last-child {
      //border-bottom: $base-border;
      border-color: $brand-grey;

      & .table__row__extra-details {
        box-shadow: inset 0 9px 10px -10px transparentize($brand-dark-grey, 0.5);
        overflow: hidden;
      }
    }

    &--no-hover {
      cursor: default !important;

      &:hover {
        box-shadow: none !important;

        & .table__row__cell {
          box-shadow: none !important;
        }
      }
    }

    &__cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5em 0.25em;
      font-size: 0.875rem;
      //border: $base-border;
      border-color: $brand-grey;
      border-left: 0;
      border-bottom: 0;
      text-align: left;
      word-break: break-word;

      &__label {
        font-weight: 700;

        &--success {
          color: $brand-success;
        }

        &--danger {
          color: $brand-danger;
        }

        &--warning {
          color: $brand-warning;
        }

        &--grey {
          color: $brand-grey;
        }
      }

      &:first-child {
        //border-left: $base-border;
        border-color: $brand-grey;
      }

      &--tooltip {
        position: relative;

        &:focus,
        &:hover {
          & .cell-tooltip {
            opacity: 1;
            transition: opacity 500ms ease;

            &--actions {
              pointer-events: all;
            }
          }
        }
      }

      &--sortable {
        display: block;
        padding: 0;

        & a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0.5em 0.25em;
          background-color: $brand-primary;
          transition: background-color 500ms ease, color 500ms ease;

          & svg {
            margin: 0;
            fill: $brand-white;
          }

          & .sort-icon {
            margin: 0 0.3125rem 0 0;
            width: 1.125rem;
          }

          &:hover {
            background-color: lighten($brand-primary, 10%);
          }
        }
      }

      &--flex {
        flex: 1;
      }

      &--center {
        justify-content: center;
      }
    }

    &__actions {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 15rem;
      overflow: hidden;

      &__button-container {
        position: absolute;
        top: 0;
        right: -10rem;
        bottom: 0;
        display: flex;
        transition: right 1000ms ease;
      }

      &__button {
        padding: 0.25rem;
        border: 0;
        background-color: $brand-secondary;
        height: 100%;
        width: 2.5rem;
        cursor: pointer;
        transition: background-color 500ms ease;

        & svg {
          width: 1.25rem;
          fill: $brand-white;
        }

        &--primary {
          background-color: $brand-primary;
        }

        &--success {
          background-color: $brand-success;
        }

        &--warning {
          background-color: $brand-warning;
        }

        &--danger {
          background-color: $brand-danger;
        }

        &:hover {
          &.table__row__actions__button {
            background-color: darken($brand-secondary, 10%);

            &--primary {
              background-color: darken($brand-primary, 10%);
            }

            &--success {
              background-color: darken($brand-success, 10%);
            }

            &--warning {
              background-color: darken($brand-warning, 10%);
            }

            &--danger {
              background-color: darken($brand-danger, 10%);
            }
          }
        }
      }
    }

    &:hover {
      & .table {
        &__row {
          &__actions {
            &__button-container {
              right: 0;
              transition: right 300ms ease;
            }
          }
        }
      }
    }

    &.table__row--button {
      justify-content: center;
      padding: 0.75rem;
      color: $brand-white;
      background-color: $brand-secondary;
      transition: background-color 500ms ease;

      &:focus,
      &:hover {
        background-color: darken($brand-secondary, 15%);
        border: none;
        box-shadow: none !important;
      }
    }

    // Tables with Extra Details
    //
    // This is the markup used to display the extra details in each table row
    //
    // Markup:
    // <div class="table table--expandable xs-24"><div class="table__row table__row--header"><div class="table__row__cell table__row__cell--sortable xs-8"><a><i aria-hidden="true" class="fa--left fa fa-chevron-down"></i>Column 1</a></div><div class="table__row__cell table__row__cell--sortable xs-8"><a>Column 2</a></div><div class="table__row__cell table__row__cell--sortable xs-8"><a>Column 3</a></div></div><div class="table__row"><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div><div class="table__row__extra-details table__row__extra-details--open xs-24"><div class="properties-container col-xs-24 col-sm-12"><div class="property"><span class="property__key">Property Key</span><span class="property__value">Property Value</span></div><div class="property"><span class="property__key">Property Key</span><span class="property__value">Property Value</span></div><div class="property"><span class="property__key">Property Key</span><span class="property__value">Property Value</span></div></div><div class="properties-container col-xs-24 col-sm-12"><div class="property"><span class="property__key">Property Key</span><span class="property__value">Property Value</span></div><div class="property"><span class="property__key">Property Key</span><span class="property__value">Property Value</span></div><div class="property"><span class="property__key">Property Key</span><span class="property__value">Property Value</span></div></div></div></div><div class="table__row"><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div><div class="table__row__cell xs-8">Data</div></div></div></div>
    //
    // Styleguide Components.Tables.ExtraDetails
    &__extra-details {
      max-height: 0;
      width: 100%;
      padding: 0;
      background-color: $brand-white;
      overflow: hidden;
      overflow-y: auto;
      //border: $base-border;
      border-color: $brand-grey;
      border-bottom: 0;
      border-top: 0 solid $brand-dark-grey;
      cursor: default;
      box-shadow: inset 0 9px 10px -10px transparentize($brand-dark-grey, 0.5), inset 0 -8px 10px -10px transparentize($brand-dark-grey, 0.5);
      transition: max-height 500ms ease, border-top 100ms ease 400ms;

      &--open {
        max-height: 100rem;
        //border-top: $base-border;
        border-color: $brand-grey;
        transition: max-height 500ms ease, border-top 100ms ease;
      }
    }

    &--header {
      background-color: $brand-primary !important;
      color: #fff;
      border-color: $brand-primary;
      position: relative;
      z-index: 10;

      @media all and (-ms-high-contrast: none) {
        position: initial;
        top: 0 !important;
      }

      &:hover {
        outline: none !important;
      }

      &.stick {
        width: calc(100% - 1rem);

        @media (min-width: $screen-sm) {
          width: calc(100% - 3rem);
        }

        @media (min-width: $screen-md) {
          width: $container-md - 3rem;
        }

        @media (min-width: $screen-lg) {
          width: $container-lg - 3rem;
        }

        @media (min-width: $screen-xl) {
          width: $container-xl - 3rem;
        }

        @media (min-width: $screen-xxl) {
          width: $container-xxl - 3rem;
        }
      }

      & .table__row__cell {
        font-weight: 500;
        height: 3.75em;
        border-color: $brand-primary !important;
      }

      & a {
        color: #fff;

        &:hover {
          color: $brand-warning;
          text-decoration: none;
        }
      }
    }

    &--sticky {
      position: sticky;
      z-index: 100;
      top: 0;
    }

    &--footer {
      background-color: #fff !important;

      & .table__row__cell {
        //border-left: $base-border;
        border-color: $brand-grey;

        @media (min-width: $screen-lg) {
          border-left: 0;
        }
      }

      &:hover {
        box-shadow: none !important;

        & .table__row__cell {
          box-shadow: none !important;
        }
      }
    }

    &--danger {
      color: $brand-white;
      background-color: #c36f6f !important;

      &:hover {
        box-shadow: inset -4px -2px 0 -2px darken($brand-danger, 15%), inset 4px -2px 0 -2px darken($brand-danger, 15%) !important;

        & .table__row__cell {
          box-shadow: inset -2px 4px 0 -2px darken($brand-danger, 15%), inset -2px -4px 0 -2px darken($brand-danger, 15%) !important;
        }
      }

      & .property {
        color: $brand-dark-grey;
      }
    }

    &--grey {
      background-color: $brand-medium-grey !important;
    }

    &--success {
      color: $brand-white;
      background-color: $brand-success !important;

      &:hover {
        box-shadow: inset -4px -2px 0 -2px darken($brand-success, 15%), inset 4px -2px 0 -2px darken($brand-success, 15%) !important;

        & .table__row__cell {
          box-shadow: inset -2px 4px 0 -2px darken($brand-success, 15%), inset -2px -4px 0 -2px darken($brand-success, 15%) !important;
        }
      }

      & .property {
        color: $brand-dark-grey;
      }
    }

    &--warning {
      color: $brand-white;
      background-color: $brand-warning !important;

      &:hover {
        box-shadow: inset -4px -2px 0 -2px darken($brand-warning, 15%), inset 4px -2px 0 -2px darken($brand-warning, 15%) !important;

        & .table__row__cell {
          box-shadow: inset -2px 4px 0 -2px darken($brand-warning, 15%), inset -2px -4px 0 -2px darken($brand-warning, 15%) !important;
        }
      }

      & .property {
        color: $brand-dark-grey;
      }
    }

    &--white {
      color: $brand-dark-grey;
      background-color: $brand-white !important;

      & .property {
        color: $brand-dark-grey;
      }
    }

    &:focus {
      z-index: 100;
    }
  }

  &--expandable {
    & .table__row {
      cursor: pointer;

      &:hover {
        box-shadow: inset -4px -2px 0 -2px $brand-primary, inset 4px -2px 0 -2px $brand-primary;

        & .table__row__cell {
          box-shadow: inset -2px 4px 0 -2px $brand-primary, inset -2px -4px 0 -2px $brand-primary;
        }
      }

      &.table__row--header {
        &:hover {
          box-shadow: none;

          & .table__row__cell {
            box-shadow: none;
          }
        }
      }

      &__cell {
        flex: none;
        min-height: 2.5em;
      }

      &--header {
        cursor: default;
      }

      &--footer {
        cursor: default;
      }
    }
  }

  &--context-menu {
    cursor: context-menu;
  }

  &--grouped {
    & .table {
      &__row {
        &:nth-child(even) {
          background-color: $brand-white;
        }

        &--alternate {
          &:nth-child(n) {
            background-color: $brand-light-grey;
          }
        }
      }
    }
  }

  & .property {
    &__value {
      white-space: normal;
    }
  }
}

.cell-tooltip {
  position: absolute;
  min-width: 15rem;
  z-index: 9999;
  padding: 1em;
  color: $brand-dark-grey;
  background-color: $brand-white;
  box-shadow: $base-box-shadow;
  opacity: 0;
  pointer-events: none;
  white-space: pre;
  transform: translateY(-50%);
  transition: opacity 500ms ease;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0.75em solid transparent;
    border-left: 0.75em solid transparent;
    border-right: 0.75em solid transparent;
    border-bottom: 0.75em solid transparent;
  }

  &--top {
    left: 50%;
    bottom: 90%;
    transform: translateX(-50%);

    &:before {
      top: 100%;
      left: 50%;
      border-top: 0.75em solid $brand-white;
      transform: translateX(-50%);
    }
  }

  &--left {
    top: 50%;
    right: 90%;
    transform: translateY(-50%);

    &:before {
      top: 50%;
      left: 100%;
      border-left: 0.75em solid $brand-white;
      transform: translateY(-50%);
    }
  }

  &--right {
    top: 50%;
    left: 90%;
    transform: translateY(-50%);

    &:before {
      top: 50%;
      right: 100%;
      border-right: 0.75em solid $brand-white;
      transform: translateY(-50%);
    }
  }

  &--bottom {
    top: 90%;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      left: 50%;
      bottom: 100%;
      border-bottom: 0.75em solid $brand-white;
      transform: translateX(-50%);
    }
  }

  &--actions {
    &:before {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: none !important;
    }
  }

  &--active {
    opacity: 1;
    transition: opacity 500ms ease;

    &--actions {
      pointer-events: all;
    }
  }
}

.cell-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: $brand-grey;
  transition: fill 500ms ease;

  &--button {
    cursor: pointer;

    &:hover {
      &.cell-icon {
        &--danger {
          fill: darken($brand-danger, 15%);
        }

        &--warning {
          fill: darken($brand-warning, 15%);
        }

        &--success {
          fill: darken($brand-success, 15%);
        }

        &--dark {
          fill: lighten($brand-dark-grey, 15%);
        }

        &--light {
          fill: darken($brand-light-grey, 15%);
        }

        &--grey {
          fill: lighten($brand-grey, 15%);
        }

        &--white {
          fill: darken($brand-white, 15%);
        }
      }
    }
  }

  &--default {
    fill: $brand-secondary;
  }

  &--primary {
    fill: $brand-primary;

    &:hover {
      fill: $brand-secondary;
    }
  }

  &--danger {
    fill: $brand-danger;
  }

  &--warning {
    fill: $brand-warning;
  }

  &--success {
    fill: $brand-success;
  }

  &--dark {
    fill: $brand-dark-grey;
  }

  &--light {
    fill: $brand-light-grey;
  }

  &--grey {
    fill: $brand-grey;
  }

  &--white {
    fill: $brand-white;
  }
}

.subtitle-row {
  background-color: $brand-white !important;
  font-size: 0.75rem;
}

.label-row {
  & td {
    border-bottom: none;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.value-row {
  & td {
    border-top: none;
    font-size: 1.25rem;
  }
}

table {
  &.responsive {
    .responsive-label {
      display: none;
    }

    @media (max-width: 999px) {
      display: block;
      background: transparent;
      box-shadow: none;

      & thead {
        display: none;
      }

      & tbody {
        display: flex;
        flex-wrap: wrap;

        & tr {
          display: block;
          flex: 1 0 calc(50% - 1rem);
          max-width: calc(50% - 1rem);
          margin: 0.5rem;
          border: 2px solid $brand-primary;
          box-shadow: $base-box-shadow;

          & td {
            display: block;
            border: none;
            font-weight: 500;

            &.responsive-title {
              font-size: 1.25rem;
              font-weight: 500;
              color: $brand-white;
              background: $brand-primary;
            }

            & .responsive-label {
              display: block;
              font-size: 0.75rem;
              font-weight: 400;
            }
          }
        }
      }

      &.striped {
        & tbody {
          & tr {
            &:nth-child(odd) {
              background-color: $brand-white;
            }

            &:nth-child(even) {
              background-color: $brand-white;
            }
          }
        }
      }
    }

    @media (max-width: 599px) {
      & tbody {
        & tr {
          flex: 1 0 100%;
          margin: 0.5rem 0;
          max-width: none;
        }
      }
    }
  }

  &.striped {
    & tbody {
      & tr {
        &:nth-child(odd) {
          background-color: $brand-light-grey;
        }
      }
    }
  }

  &.paged {
    position: relative;
    margin: 0 0 3rem;
  }

  &.grouped {
    & tr {
      background-color: $brand-white;

      &.group-alternate {
        border-color: $brand-secondary;

        & .responsive-title {
          background-color: $brand-secondary;
        }
      }
    }

    @media (min-width: $screen-md) {
      & tr {
        background-color: $brand-white;

        &.group-alternate {
          background-color: $brand-light-grey;

          & .responsive-title {
            background-color: transparent;
          }
        }
      }
    }
  }

  &.context-menu {
    & .context-menu__row {
      cursor: pointer;

      &--warning {
        border-color: $brand-warning;

        & .responsive-title {
          background-color: $brand-warning;
        }
      }

      @media (min-width: $screen-md) {
        cursor: context-menu;

        &:hover {
          & td {
          background-color: $brand-primary;
          }
        }

        &--warning {          
          & td {
            background-color: $brand-warning;
            border-color: $brand-warning;
          }

          &:hover {

            & td,
            & .responsive-title {
              border-color: lighten($brand-warning, 10%);
            background-color: lighten($brand-warning, 10%);
            }
          }
        }
      }
    }
  }
}

tr {
  &.bold-row {
    font-weight: 600;
  }
}
