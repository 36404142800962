// - - - - - - - - - - - - - - - - - - -
// - - Navigation
#sidenav {
  display: block;
  width: 100%;
  height: 3.25rem;
  padding: 0.25rem;
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: sidenav;
  background-color: $white;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.125);
  z-index: 5000;
  overflow: hidden;

  & #sidenav-toggle {
    display: none;
  }

  & .sidenav-container {
    display: flex;

    & #scroll-container {
      display: flex;
      flex: 1;
      height: 2.75rem;
      max-width: calc(100vw - 4.375rem);
      overflow-x: auto;
      overflow-y: hidden;
      order: 1;
    }

    & #user-profile {
      display: block;
      width: auto;
      padding: 0 0.75rem 0 0.5rem;
      border-right: 2px solid $light-gray;
      order: 0;

      & .user-profile {
        width: auto;
        height: auto;

        &__button {
          width: 2.5rem;
          height: 2.5rem;
          margin: 0.125rem 0 0;
          background-color: $brand-primary;
          color: $white;
          border-radius: 50%;
          border: none;
          outline: none !important;
          transition: background-color 400ms ease;
        }

        &__name {
          display: none;
        }
      }
    }

    & #user-menu {
      position: fixed;
      bottom: -10rem;
      left: 0.5rem;
      z-index: 1000;
      padding: 0.5rem 1rem;
      background: $white;
      opacity: 0;
      box-shadow: 1px 0px 15px rgba(0, 0, 0, 0.125);
      border-radius: 4px;
      transition: opacity 500ms ease, bottom 100ms ease 500ms, left 500ms ease;

      &.open {
        bottom: 3.75rem;
        opacity: 1;
        transition: opacity 500ms ease 100ms, bottom 100ms ease, left 500ms ease;
      }

      & ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0.875rem;
      }
    }
  }

  @media (min-width: $screen-sm) {
    width: 3.25rem;
    height: auto;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    transition: width 500ms ease;

    & .sidenav-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      & #scroll-container {
        display: block;
        width: calc(100% + 0.375rem);
        margin: 0.25rem 0;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-color: $primary $medium-light-gray;
        scrollbar-width: thin;
        transition: width 500ms ease;
        order: 1;

        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-track {
          background: $white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $light-gray;
        }

        &>cmp-nav-section {
          width: 100%;
          transition: width 500ms ease;
        }
      }

      & #user-profile {
        padding: 0.5rem 0 0;
        margin: auto 0 0.25rem;
        border-top: 2px solid $light-gray;
        border-right: none;
        text-align: left;
        order: 2;

        & .user-profile {
          width: 14.5rem;
          cursor: pointer;

          &__button {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0 0 0 0.125rem;
            background-color: $brand-primary;
            color: $white;
            border-radius: 50%;
            border: none;
            outline: none !important;
            cursor: pointer;
            transition: background-color 400ms ease;
          }

          &__name {
            display: inline-block;
            margin: 0 0 0 0.5rem;
            opacity: 0;
            font-size: 1rem;
            color: $medium-gray;
            text-transform: uppercase;
            cursor: pointer;
            transition: opacity 500ms ease, color 500ms ease;
          }

          &:hover,
          &:focus {
            & .user-profile {
              &__button {
                background-color: $brand-secondary;
              }

              &__name {
                color: $brand-secondary;
              }
            }
          }
        }
      }

      & #user-menu {
        left: 4rem;
        cursor: pointer;

        &.open {
          bottom: 0.75rem;
        }
      }
    }

    & .nav-section {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media (min-width: $screen-md) {
    & #sidenav-toggle {
      display: block;
      padding: 0 0 0.25rem;
      border-bottom: 2px solid $light-gray;
      order: 0;

      & .nav-toggle-container {
        display: flex;
        align-items: center;
        width: 17.5rem;

        & .nav-toggle-button {
          display: inline-flex;
          flex-flow: column nowrap;
          justify-content: center;
          width: 2.75rem;
          height: 2.75rem;
          margin: 0;
          position: relative;
          cursor: pointer;

          &>div {
            position: absolute;
            top: 1.25rem;
            left: 0.625rem;
            width: 1.5rem;
            height: 0.1875rem;
            border-radius: 0.5rem;
            background-color: $brand-primary;
            transition: all 500ms ease 200ms, background-color 500ms ease;

            &:first-child {
              top: 0.8125rem;
            }

            &:last-child {
              top: 1.6875rem;
            }
          }

          &:hover {
            &>div {
              background-color: $brand-secondary;
            }
          }
        }

        & .nav-toggle-label {
          display: inline-flex;
          align-items: center;
          margin: 0 0 0 0.5rem;
          opacity: 0;
          font-size: 1rem;
          color: $medium-gray;
          text-transform: uppercase;
          transition: opacity 300ms ease, color 500ms ease;
        }
      }
    }

    &.sidenav--open {
      width: 15rem;

      & .sidenav-container {
        & #scroll-container {
          width: 100%;

          &>cmp-nav-section {
            width: calc(100% - 0.25rem);
          }
        }
      }

      & #sidenav-toggle {
        & .nav-toggle-container {
          & .nav-toggle-button {
            &>div {
              &:first-child {
                transform: rotate(-45deg);
                top: 1.25rem;
              }

              &:nth-child(2) {
                height: 0;
              }

              &:last-child {
                transform: rotate(45deg);
                top: 1.25rem;
              }
            }
          }

          & .nav-toggle-label {
            opacity: 1;
            transition: opacity 500ms ease 500ms, color 500ms ease;
          }
        }
      }

      & #user-profile {
        & .user-profile {
          &__name {
            opacity: 1;
            transition: opacity 500ms ease 500ms, color 500ms ease;
          }
        }

        & #user-menu {
          left: 16rem;
        }
      }

      & .nav-section {
        width: 100%;

        &__title {
          height: 2.25rem;
          padding: 0.25rem 0.5rem;
          opacity: 1;
          transition: opacity 500ms ease 500ms, height 500ms ease,
            padding 500ms ease;
        }

        &__item {
          &__label {
            opacity: 1;
            transition: opacity 1000ms ease 500ms, color 500ms ease;
          }
        }
      }
    }
  }
}

.nav-section {
  display: inline-flex;
  padding: 0 0.5rem;
  border-right: 2px solid $light-gray;

  &__title {
    display: none;
  }

  &__item {
    width: 2.75rem;
    max-height: 2.75rem;
    overflow: hidden;
    transition: max-height 400ms ease-out;

    &>a,
    &>div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      text-decoration: none;

      &.loading {
        display: none;
      }
    }

    &__icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2.75rem;
      height: 2.75rem;

      & svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $brand-primary;
        transition: fill 500ms ease;
      }
    }

    &__label {
      display: none;
      text-transform: capitalize;
    }

    &__tooltip {
      position: fixed;
      left: -5rem;
      opacity: 0;
      padding: 0.5em 1em;
      font-size: 0.75rem;
      background-color: $brand-white;
      border: 1px solid $light-gray;
      border-radius: 0.25rem;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.125);
      text-transform: capitalize;
      pointer-events: none;
      transition: opacity 300ms ease, left 50ms ease 300ms;

      &--active {
        @media (min-width: $screen-md) {
          left: 4rem;
          opacity: 1;
          transition: opacity 300ms ease 50ms, left 50ms ease;
        }
      }
    }

    &__subitems {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 3.25rem;
      max-height: 0;
      margin: 0;
      padding: 0 1.5rem;
      overflow: hidden;
      background-color: $brand-white;
      box-shadow: 0 -7px 10px -6px rgba(0, 0, 0, 0.125), inset 0 -6px 10px -5px rgba(0, 0, 0, 0.125);
      list-style: none;
      transition: all 500ms ease-out;

      &__title {
        margin: 0 0 1rem;
        text-transform: uppercase;
        color: $medium-gray;
        font-size: 1rem;
      }

      &__item {
        width: 100%;
        font-size: 0.875rem;
        margin: 0 0 0.625rem;

        & a {
          display: block;
          color: $brand-dark-grey;

          &.active {
            color: $brand-secondary;
            pointer-events: none;
          }
        }

        &:hover {
          & a {
            color: $brand-secondary;
          }
        }
      }
    }

    &--dropdown {
      position: relative;

      & i {
        display: none;
        position: absolute;
        right: 0.75rem;
        top: 0.875rem;
        color: $brand-dark-grey;
        transition: all 400ms ease;
      }
    }

    &--open {
      max-height: 25rem;
      z-index: 11; // Above other nav items when popup menu is open
      transition: max-height 400ms ease-in;

      & i {
        transform: rotate(90deg);
      }

      & .nav-section__item {
        &__subitems {
          padding: 1.5rem;
          max-height: 20rem;
          transition: all 500ms ease-in;
        }
      }
    }

    &--sidebar-closed {
      max-height: 2.75rem;
    }

    &--active {
      cursor: default !important;

      & .nav-section__item {
        &__icon {
          & svg {
            fill: $brand-secondary;
          }
        }

        &__label {
          color: $brand-secondary;
        }
      }

      & i {
        color: $brand-secondary;
      }
    }
  }

  &:last-child {
    border-right: none;
  }

  @media (min-width: $screen-sm) {
    display: block;
    width: calc(100% - 0.375rem);
    padding: 0.5rem 0 1rem;
    border-right: none;
    border-bottom: 2px solid $light-gray;

    &__title {
      display: block;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      color: $medium-gray;
      text-transform: uppercase;
      transition: opacity 500ms ease, height 500ms ease 500ms,
        padding 500ms ease 500ms;
    }

    &__item {
      width: 14.5rem;
      cursor: pointer;
      position: relative;
      z-index: 10;
      background-color: transparent;

      &__label {
        display: inline-flex;
        align-items: center;
        margin: 0 1rem;
        color: $brand-dark-grey;
        text-decoration: none;
        opacity: 0;
        transition: opacity 500ms ease, color 500ms ease;
      }

      &:hover {
        & .nav-section__item {
          &__icon {
            & svg {
              fill: $brand-secondary;
            }
          }

          &__label {
            color: $brand-secondary;
          }
        }

        & i {
          color: $brand-secondary;
        }
      }

      &__subitems {
        top: 0;
        bottom: 0;
        left: 3.25rem;
        right: initial;
        width: 0;
        padding: 1.75rem 0;
        background-color: $brand-white;
        box-shadow: 7px 0 10px -6px rgba(0, 0, 0, 0.125), inset 5px 0 10px -5px rgba(0, 0, 0, 0.125);
        max-height: none;
        transition: all 500ms ease;

        &__item,
        &__title {
          width: 12.5rem;
        }
      }

      &--open {
        z-index: 11;

        & .nav-section__item {
          &__subitems {
            width: 16rem;
            padding: 1.75rem 1.5rem;
            box-shadow: 7px 0 10px -6px rgba(0, 0, 0, 0.125), inset 5px 0 10px -5px rgba(0, 0, 0, 0.125);
            max-height: none;
          }
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    &__item {
      &--dropdown {
        & i {
          display: block;
        }
      }

      &__subitems {
        position: relative;
        top: initial;
        left: initial;
        bottom: initial;
        width: 100%;
        padding: 0.75rem;
        background: transparent;
        box-shadow: none;

        &__title {
          display: none;
        }

        &__item {
          width: 100%;
        }
      }

      &--open {
        & .nav-section__item {
          &__subitems {
            padding: 0.75rem;
            box-shadow: none;
          }
        }
      }
    }
  }
}

#user-details {
  & .page-alert {
    &__title {
      margin: 0;

    }

    &__message {
      white-space: normal;

      & label {
        font-size: 1rem;
      }

      & input {
        font-size: 1rem;
      }
    }
  }
}

#fake-sidenav {
  height: 3.25rem;
  width: 100%;
  background-color: $white;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.125);
  z-index: 5000;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (min-width: $screen-sm) {
    height: 100%;
    width: 3.25rem;
    top: 0;
  }
}
