.service-contract-header {
  width: 100%;
  position: relative;
}

.contract-details {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: auto;
  width: 100vw;
  max-width: 100%;

  &__customer {
    width: 100%;
    padding: 0 0 1rem;
    border-bottom: 2px solid $brand-medium-grey;

    &__contact-info {
      & .form-inline {
        margin: 0;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      line-height: 1;
      font-weight: 400;
      margin: 0;
      line-height: 1.25;
    }

    &__vehicle {
      font-weight: 300;
      margin: 0 0 1.5em;
      line-height: 1.25;
    }
  }

  &__contract {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0 0;

    &__actions {
      margin-top: auto;

      & .btn {
        margin: 1rem 0 0.5rem;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  & .card__badge {
    top: 0;
    right: 0;
  }

  @media (min-width: $screen-sm) {
    margin: 0 0 1.5em;
    width: 100%;
  }

  @media (min-width: $screen-lg) {
    flex-wrap: nowrap;

    &__customer {
      max-width: calc(100% - 20rem);
      padding: 0 1.5rem 0 0;
      border-right: 2px solid $brand-medium-grey;
      border-bottom: 0;

      &__contact-info {
        & .form-inline {
          margin: 0;
        }
      }

      &__name {
        display: flex;
        align-items: center;
        line-height: 1;
        font-weight: 400;
        margin: 0;
        line-height: 1.25;
      }

      &__vehicle {
        font-weight: 300;
        margin: 0 0 1.5em;
        line-height: 1.25;
      }
    }

    &__contract {
      max-width: 20rem;
      padding: 0 0 0 1.5rem;
    }
  }

  @media (min-width: $screen-xl) {
    &__customer {
      max-width: calc(100% - 30rem);
    }

    &__contract {
      max-width: 30rem;
    }
  }
}

.contract-attribute {
  &__title {
    font-weight: 400;
    margin: 0;
    line-height: 1.25;
  }

  &__value {
    font-weight: 300;
    margin: 0;
    line-height: 1.25;
  }
}

.service-contract-footer {
  width: 100%;
  margin: 1em 0 0;
}

.contract-expiration {
  position: absolute;
  top: 0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  border-radius: 0;
  text-align: center;

  @media (min-width: $screen-sm) {
    left: initial;
    right: 0;
    border-radius: $base-border-radius;
  }

  @media (min-width: $screen-lg) {
    top: 0;
  }
}

#claims-content {
  width: 100%;
  position: relative;
}

#claim-modals {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1em;

  & .form-group {
    width: 100%;

    & .btn {
      width: 100%;
      margin: 0 0 0.5em;
    }
  }

  @media (min-width: $screen-sm) {
    justify-content: flex-end;

    & .form-group {
      & .btn {
        width: calc(50% - 0.5rem);
        margin: 0 0.25rem 0;

        &:first-child {
          width: calc(50% - 0.4375rem);
          margin: 0 0.25rem 0 0;
        }

        &:last-child {
          width: calc(50% - 0.4375rem);
          margin: 0 0 0 0.25rem;
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    justify-content: flex-end;

    & .form-group {
      width: auto;

      & .btn {
        width: auto;

        &:first-child,
        &:last-child {
          width: auto;
        }
      }
    }
  }
}



.coupons {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 1em 0 0;
  width: 100%;

  @media (min-width: $screen-sm) {
    margin: 1em -0.25em 0;
    width: calc(100% + 0.5em);
  }

  @media (min-width: $screen-md) {
    margin: 1em -0.75em 0;
    width: calc(100% + 1.5em);
  }
}

.coupon {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 0.75em;
  padding-bottom: 2em;
  min-width: 17rem;
  max-width: 100%;

  &__code,
  &__name {
    font-weight: 300;
    line-height: 1;
  }

  &__code {
    max-width: 75%;
    font-size: 1.25em;
    font-weight: 400;
    margin: 0 0 1em;

    &+.coupon__name {
      margin: -1.25em 0 1em;
    }
  }

  &__name {
    margin: 0 0 1em;
    font-size: 0.875em;
  }

  &__status {
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 0.875rem;
    font-weight: 500;

    &--available {
      color: $brand-success;
    }

    &--expired,
    &--cancelled,
    &--used {
      color: $brand-danger;
    }
  }

  &__value {
    font-size: 2em;
    font-weight: 300;
    width: 100%;
    text-align: center;
    margin-top: auto;

    &__label {
      display: block;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &--available {
    @extend .outline-cw;
    cursor: pointer;
  }

  &.coupon--available {

    &:after {
      bottom: -1px;
    }

    &:hover {

      &:before,
      &:after {
        width: calc(100% + 1px);
        height: calc(100% + 1px);
      }
    }
  }

  &--expired,
  &--cancelled {
    cursor: not-allowed;
  }

  @media (min-width: $screen-sm) {
    flex: 1 0 45%;
    margin: 0 0.25em 0.5em;
    max-width: calc(50% - 0.5em);

    &__code {
      font-size: 1.5em;
    }

    &__name {
      font-size: 1em;
    }

    &__value {
      font-size: 2.25em;
    }
  }

  @media (min-width: $screen-md) {
    flex: 1 0 45%;
    margin: 0 0.75em 1.5em;
    max-width: calc(50% - 1.5em);

    &__code {
      font-size: 1.75em;
    }

    &__name {
      font-size: 1.125em;
    }

    &__value {
      font-size: 2.75em;
    }
  }

  @media (min-width: $screen-lg) {
    flex: 1 0 30%;
    margin: 0 0.75em 1.5em;
    max-width: calc(33.3333% - 1.5em);
    min-width: 22.5rem;
  }

  @media (min-width: $screen-xl) {
    flex: 1 0 20%;
    margin: 0 0.75em 1.5em;
    max-width: calc(25% - 1.5em);
  }
}

.modal-in-modal {
  position: fixed;
  top: -100vh;
  left: -100vw;
  z-index: 999;
  height: 98vh;
  width: 98vw;
  background-color: $brand-white;
  padding: 4.5rem 0.5rem 0.5rem;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: top 100ms ease 500ms, left 100ms ease 500ms, opacity 500ms ease;

  &.active {
    top: 50%;
    left: 50%;
    opacity: 1;
    transition: top 100ms ease, left 100ms ease, opacity 500ms ease 100ms;
  }

  @media (min-width: $screen-sm) {
    height: 90.25vh;
    width: 95vw;
    padding: 4.5rem 1rem 1rem;
  }

  @media (min-width: $screen-md) {
    width: 80vw;
    padding: 4.5rem 2rem 2rem;
  }

  @media (min-width: $screen-lg) {
    width: 75.5rem;
  }
}

.modal-in-modal div,
.modal-in-modal iframe {
  height: 100%;
}

#claims-search {
  width: 100%;
  min-width: 0;
  margin: 0 0 0.5rem;
  padding: 0;

  @media (min-width: $screen-md) {
    margin: 0 0 1rem;
  }

  @media (min-width: $screen-lg) {
    width: auto;
    flex: 5 0 auto;
    margin: 0 1rem 1.25rem 0;
  }
}

#pending-claims-buttons {
  width: 100%;
  margin: 0;

  @media (min-width: $screen-md) {
    margin: 0 0 0.5rem;
  }

  @media (min-width: $screen-lg) {
    width: auto;
    flex: 1 0 auto;
    margin: 0;
    padding: 0 0 0.75rem;
  }
}

#contracts-page {
  min-height: 0;
}

#claims-page {
  min-height: 0;
  max-width: 100%;
}

#contracts-claims-comments {
  & .contract-comments {
    &__item {
      padding: 0.25rem;

      &__icon {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  }
}

#claims-layout-toggle {
  @media (min-width: $screen-md) {
    position: absolute;
    top: 1.5rem;
    right: 0;
    z-index: 10;
  }
}

#services .table {
  margin: 1rem 0 2rem;
}
