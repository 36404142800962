/* Modal Styles */
.modal {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
  background-color: transparentize($brand-dark-grey, 0.25);
  opacity: 0;
  display: flex !important;
  transition: opacity 500ms ease;

  &.in {
    opacity: 1;
  }

  &-dialog {
    @extend .container;
    display: block;
    padding: 0;
    margin: 0;
    max-height: 90vh;
    max-width: 95%;
    overflow: hidden;
    background-color: $brand-white;
    border-radius: $base-border-radius;
    box-shadow: $base-box-shadow;

    &--dynamic {
      overflow: visible;

      & .modal-content {
        height: auto;
        overflow: visible;

        & #modal-actions {
          bottom: -4.5rem;
        }
      }
    }
  }


  &-content {
    background-color: $brand-white;
    height: 90vh;
    width: 100%;
    overflow-y: hidden;
    position: relative;
    cursor: default;
  }

  &-header {
    padding: 1rem 1rem 0.5rem;
    border-bottom: $base-border;
    border-color: $brand-medium-grey;
  }

  &-body {
    height: calc(100% - 9.25rem);
    padding: 0.5rem;
    overflow-y: auto;
    scrollbar-color: $brand-primary $brand-medium-grey;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px
    }

    &::-webkit-scrollbar-track {
      background: $brand-medium-grey;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-primary;
    }

    & fieldset {
      border: 0;
      padding: 0;
      margin: 0 0 1em;
    }

    & legend {
      width: 100%;
      font-size: 1.125rem;
      font-weight: 500;
      padding: 0.5em 0;
      border-bottom: $base-border;
      border-color: $brand-medium-grey;
    }
  }

  &-title {
    font-size: 1.25rem;
    margin: 0 0 0.25em;
    padding: 0 2.5rem 0 0;
  }

  &-info {
    position: absolute;
    left: 50%;
    bottom: 0.5rem;
    font-size: 0.75rem;
    transform: translateX(-50%);
  }

  @media (min-width: $screen-sm) {
    &-body {
      padding: 1rem;
    }

    &-header {
      padding: 1rem 1rem 0.5rem;
    }
  }

  @media (min-width: $screen-md) {
    &-body {
      padding: 1.5rem;
    }

    &-header {
      padding: 1.5rem 1.5rem 0.5rem;
    }

    &-title {
      font-size: 1.5rem;
    }
  }

  @media (min-width: $screen-lg) {

    &-dialog {
      max-width: 75rem;
    }
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  @media (min-width: $screen-sm) {
    top: 0.625rem;
    right: 1rem;
  }

  @media (min-width: $screen-md) {
    top: 1rem;
    right: 1.5rem;
  }
}

#modal-actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4.5rem;
  margin: 0;
  padding: 0 1rem;
  background-color: $brand-white;
  border-top: $base-border;
  border-color: $brand-medium-grey;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &>button,
  &>.btn {
    margin: 0 0 0 1rem;
  }
}

/* End Modal Styles */
