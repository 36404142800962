.highcharts {
  &-data-label {
    display: none;
  }

  &-pie-series {
    & .highcharts-data-label {
      display: inline-block;
    }
  }
}
