.progress-bar {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 15rem;
  height: 2.5rem;
  background-color: $brand-white;
  border: 2px solid $brand-dark-grey;

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    &--danger {
      background-color: lighten($brand-danger, 10%);
    }

    &--warning {
      background-color: $brand-warning;
    }

    &--success {
      background-color: $brand-success;
    }
  }

  &__text {
    position: relative;
    z-index: 10;
    padding: 0 0.5rem;
  }

  @media (min-width: $screen-md) {
    min-width: 20rem;
  }
}
