.login-container {
  @extend .container;
  padding: 0;
}

.login-bg {
  background-image: url('https://images.maintenanceprogram.com/images/mainLogin.png');
  margin: 0 auto;
  max-width: 62.5rem;
  padding: 2.5rem 0 1rem;
  position: relative;

  @media (min-width: $screen-sm) {
    padding: 4.5rem 2rem 2rem;
  }
}

.login-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  padding: 1em 0;
  text-transform: uppercase;
}

.form-signin {
  display: flex;
  flex-wrap: wrap;
  border: $base-border;
  border-color: $brand-medium-grey;
  padding: 1em;
  border-radius: $base-border-radius;
  height: 11rem;

  & input {
    margin: 0 0 0.5em;
    width: 100%;
  }
}

#bbb-logo {
  text-align: center;
}

#forgot-password {
  display: block;
  float: none;
  margin: 0 0 0 auto;
  padding: 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: $brand-secondary;
  cursor: pointer;
  transition: color 500ms ease;

  &:hover {
    color: darken($brand-secondary, 15%);
  }
}
