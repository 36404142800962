// - - - - - - - - - - - - - - - - - - -
// - - Header

#header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: header;
  padding: 0 2rem;
  background-color: $white;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 4999;
  transition: background-color 500ms ease;

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 0.875rem;
    color: $medium-gray;
    text-transform: uppercase;
    transition: color 500ms ease;
  }

  &:hover {
    background-color: $light-gray;

    & a {
      color: $brand-secondary;
    }
  }

  @media (min-width: $screen-sm) {
    -ms-grid-column: 2;
  }
}

#fake-header {
  background-color: $white;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 4999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2rem;
}
