// Font Awesome
//
// Add on to the base font awesome class.  This examle uses the fa-times icon. The fa-times can be swapped for any other icon. Just used for spacing examples. This is example is to display the font awesome modifiers.
//
// Markup:
// <i class="fa {{modifier_class}} fa-times"></i>
//
// .fa--left - Used when the icon is to the left of text
// .fa--right - Used when the icon is to the right of text
// .fa--danger - Icon colored using the danger color
// .fa--light - Icon colored using a light color
// .fa--primary - Icon colored using the primary color
// .fa--secondary - Icon colored using the secondary color
// .fa--success - Icon colored using the success color
// .fa--warning - Icon colored using the warning color
// .fa--blink - Blinking icon
// .fa--spin-right - Spinning clockwise icon
// .fa--spin-left - Spinning counterclockwise icon
//
// Styleguide Components.Utilities.FontAwesome
.fa {
  &--left {
    margin: 0 0.3125em 0 0;
  }

  &--right {
    margin: 0 0 0 0.3125em;
  }

  &--danger {
    color: $brand-danger;
  }

  &--light {
    color: $brand-light-grey;
  }

  &--primary {
    color: $brand-primary;
  }

  &--secondary {
    color: $brand-secondary;
  }

  &--success {
    color: $brand-success;
  }

  &--warning {
    color: $brand-warning;
  }
  &--blink {
    @extend .blink;
  }
  &--spin-right {
    @extend .spin-right;
  }
  &--spin-left {
    @extend .spin-left;
  }
}
