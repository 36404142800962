.icon-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  background: none;
  border: none;

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    fill: $brand-light-grey;
    transition: fill 500ms ease;

    & .stroke {
      fill: none;
      stroke: $brand-light-grey;
      stroke-width: 3.75;
      stroke-linejoin: miter;
      stroke-linecap: round;
      stroke-miterlimit: 3;
      transition: stroke 500ms ease;
    }
  }

  &__text {
    display: block;
    width: 100%;
    color: $brand-light-grey;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.25em 0;
    transition: color 500ms ease;
  }

  &:hover {
    & .icon-link {
      &__icon {
        fill: $brand-secondary;

        & .fill-stroke,
        & .stroke {
          stroke: $brand-secondary;
        }
      }

      &__text {
        color: $brand-secondary;
      }
    }
  }
  @media (min-width: $screen-sm) {
    &__icon {
      width: 2.75rem;
      height: 2.75rem;
      min-width: 2.75rem;
    }
  }
  @media (min-width: $screen-md) {
    &__icon {
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
    }
  }
}

.icon-toggle {
  display: flex;
  height: 2.5rem;
  width: 5rem;
  background-color: $brand-white;
  border: 2px solid $brand-dark-grey;
  overflow: hidden;
  position: relative;

  & input {
    position: absolute;
    top: -100rem;
    left: -100rem;
  }

  &__option {
    flex: 1 0 50%;
    max-width: 50%;
    display: block;
    padding: 0.5rem;
    position: relative;
    z-index: 100;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 500ms ease;

    & svg {
      max-width: 1.5rem;
      fill: $brand-dark-grey;
      transition: fill 500ms ease;
    }

    &:hover {
      & svg {
        fill: $brand-grey;
      }
    }

    &--active {
      & svg {
        fill: $brand-white;
      }

      &:hover {
        background-color: transparent;

        & svg {
          fill: $brand-white;
        }
      }
    }
  }

  &__switch {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 50%;
    height: 100%;
    margin-left: 50%;
    background-color: $brand-secondary;
    transition: margin-left 500ms ease;

    &--off {
      margin-left: 0;
    }
  }
}

.inline-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.25rem 0 0;
}

svg {
  min-width: 1.125rem;
  min-height: 1.125rem;
  max-width: 100%;
  max-height: 100%;
  fill: $brand-white;
}
