// - - - - - - - - - - - - - - - - - - -
// - - Footer

#footer {
  display: none;

  & .footer {
    &__modified {
      margin: 0 auto;
      width: calc(100% - 1rem);
      text-align: center;
      font-size: 0.6875rem;
      padding: 0.375rem;
      background-color: #fff;
      border-bottom: 2px solid #ebeff3;
    }

    &__info {
      display: flex;
      padding: 0.5rem 2rem;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      width: 100%;

      &__logos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        & div {
          flex: none;
          height: 2.25rem;
          margin: 0 0.5rem;
          padding: 0.25rem;
          text-align: center;

          & img {
            width: auto;
            height: 100%;
          }
        }

        @media (min-width: $screen-lg) {
          flex-wrap: nowrap;
          padding: 0 1rem;

          & div {
            width: auto;
            padding: 0.125 0.5rem;
          }
        }
      }

      &__support {
        font-size: 0.75rem;
        text-align: center;
        padding: 0 1rem;

        & a {
          color: $brand-secondary;
          font-weight: 500;
          white-space: nowrap;

          &:hover {
            color: $brand-dark-grey;
          }
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 0 100%;
    align-self: flex-end;
    margin: 1rem 0 0;
    background-color: $white;
    box-shadow: 1px -1px 10px rgba(0, 0, 0, 0.075);
    z-index: 1000;
  }

  @media (min-width: $screen-lg) {
    & .footer__info {
      &__logos {
        & div {
          height: 2.5rem;
        }
      }
    }
  }
}
