// Searchbar
//
// A wrapper for searchbars
//
// Markup:
// <div class="searchbar {{modifier_class}}"><div class="input-group input-group--icon"><input placeholder="Search..." type="text" class="input-control">          <div class="input-group__icon"><i aria-hidden="true" class="fa fa-search"></i></div></div>
//
// .searchbar--outline - Searchbar with border
//
// Styleguide Components.Forms.Searchbar

.searchbar {
  min-width: 100%;
  margin: 0 0 1em;

  & .input-group {
    width: 100%;
    height: 100%;
    padding: 0 4rem 0 0;
    font-size: 1.125rem;
    color: $brand-dark-grey;
    background-color: $brand-light-grey;

    & input {
      width: 100%;
      height: 100%;
      padding: 0.5em;
      font-size: 0.8125rem;
      margin: 0;
      border: 0;
      border-radius: 0;
      color: $brand-dark-grey;
      background-color: transparent;

      &:-webkit-placeholder,
      &:placeholder {
        color: $brand-grey;
      }

      &:hover {
        background-color: darken($brand-light-grey, 25%);
      }

      &:focus {
        outline: none;
        background-color: $brand-white;
        border-color: $brand-primary;
        border-width: 2px;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      padding: 0.625em 0;
      text-align: center;
      height: 100%;

      &:hover {
        background-color: darken($brand-light-grey, 25%);
      }

      & svg {
        max-height: 100%;
      }

      &--filter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        & svg {
          width: 1.25rem;
          fill: $brand-dark-grey;

          @media (min-width: $screen-md) {
            width: 1.5rem;
          }

          @media (min-width: $screen-lg) {
            width: 1.75rem;
          }
        }
      }
    }
  }

  &__filters {
    background-color: $brand-white;
    max-height: 0;
    padding: 0 0.75em;
    overflow: hidden;
    transition: max-height 500ms ease, padding 300ms linear;

    &--open {
      max-height: 17.5rem;
      padding: 0.75em;
    }

    & .checkbox,
    & label,
    & input[type='checkbox'] {
      margin-bottom: 0;
    }

    @media (min-width: $screen-md) {
      &--open {
        max-height: 7.5rem;
      }
    }
  }

  &--outline {
    & .input-group {
      background-color: $brand-white;

      & input {
        color: $brand-dark-grey;
        background-color: transparent;
        border: $base-border;
        border-color: $brand-dark-grey;
        border-radius: $base-border-radius;
        border-right: 0;

        &:-webkit-placeholder,
        &:placeholder {
          color: $brand-grey;
        }

        &:hover {
          background-color: $brand-light-grey;
        }
      }

      &__icon {
        color: $brand-dark-grey;
        border: $base-border;
        border-color: $brand-dark-grey;
        background-color: transparent;

        &:hover {
          background-color: $brand-light-grey;
        }
      }
    }

    & .searchbar__filters {
      border: 0px solid $brand-grey;
      transition: max-height 500ms ease, padding 300ms linear, border 50ms ease 450ms;

      &--open {
        border: $base-border;
        border-color: $brand-dark-grey;
        border-top: 0;
        transition: max-height 500ms ease, padding 300ms linear;
      }
    }
  }

  &--dealers {
    margin: 0;

    @media (min-width: $screen-sm) {
      margin: 0 0 1em;
    }
  }

  @media (min-width: $screen-md) {

    & .input-group {
      padding: 0 4rem 0 0;

      & input {
        padding: 0.5em 0.75em;
        font-size: 1rem;
      }
    }
  }


  @media (min-width: $screen-lg) {

    & .input-group {
      padding: 0 4rem 0 0;

      & input {
        padding: 0.75em 1em;
      }
    }
  }
}
