#dashboard {
  -ms-grid-rows: 4rem 1fr 3.25rem;
  grid-template-rows: 4rem 1fr 3.25rem;

  @media (min-width: $screen-sm) {
    -ms-grid-rows: 4rem 1fr;
    grid-template-rows: 4rem 1fr;
  }
}

#header {
  &:hover {
    background-color: #fff;
  }
}

.header {
  &__logos {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__agent {
      display: none;
    }

    &__agent,
    &__sponsor {
      margin: 0 1rem;

      &>img {
        height: 3rem;
      }
    }

    @media (min-width: $screen-md) {
      &__agent {
        display: block;
      }
    }
  }
}

#main {
  &>#page-content {
    min-height: calc(100vh - 7.75rem);
    max-height: calc(100vh - 7.75rem);

    @media (min-width: $screen-sm) {
      min-height: calc(100vh - 4rem);
      max-height: calc(100vh - 4rem);
    }
  }
}
