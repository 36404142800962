/* Utilities */
@import 'utilities/alert',
'utilities/animated',
'utilities/badges',
'utilities/bullets',
'utilities/dealer-comments',
'utilities/emoji',
'utilities/feedback',
'utilities/font-awesome',
'utilities/hover',
'utilities/layout',
'utilities/labels',
'utilities/lists',
'utilities/page-alert',
'utilities/page-overlay',
'utilities/page-scroll',
'utilities/pagination',
'utilities/prompt',
'utilities/progress-bar',
'utilities/properties',
'utilities/shepherd',
'utilities/sticky-header',
'utilities/tooltip';

.pdf-iframe {
  width: 100%;

  & .loading-container {
    display: flex;
    justify-content: center;
  }

  & iframe {
    width: 100%;
    min-height: 65vh;

    &.hidden {
      display: block;
      opacity: 0;
      position: fixed;
      top: -200%;
      left: -200%;
    }
  }
}

/* End Utilities */
