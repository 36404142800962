#legacy-alert {
  height: 3rem;
  font-size: 0.625rem;
  color: $medium-gray;
  justify-content: center;
  margin: 0;

  @media (min-width: $screen-sm) {
    height: 2.25rem;
  }

  @media (min-width: $screen-md) {
    height: 1.5rem;
  }

  &+#page-content {
    min-height: calc(100vh - 8.25rem);
    max-height: calc(100vh - 8.25rem);

    @media (min-width: $screen-sm) {
      min-height: calc(100vh - 4.25rem);
      max-height: calc(100vh - 4.25rem);
    }

    @media (min-width: $screen-md) {
      min-height: calc(100vh - 3.5rem);
      max-height: calc(100vh - 3.5rem);
    }
  }
}
