.page-alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 99999;
  opacity: 0;
  transition: opacity 500ms;

  &--open {
    opacity: 1;
  }
}

.page-alert {
  width: 95%;
  padding: 1em;
  background-color: $brand-white;
  border: 1px solid $brand-medium-grey;
  box-shadow: $base-box-shadow;

  &__message {
    text-align: left;
    font-size: 1.125rem;
    margin: 0 0 1em;
    color: $brand-dark-grey;
    white-space: pre-line;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;

    & > .btn {
      width: 100%;
      margin: 0 0 0.75rem;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media (min-width: $screen-sm) {
    width: auto;
    max-width: 30rem;
    padding: 1.5em;

    &__message {
      font-size: 1.25rem;
    }

    &__actions {
      flex-wrap: nowrap;

      & > .btn {
        flex: 1;
        margin: 0 0.75rem 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    width: auto;
  }

  @media (min-width: $screen-lg) {
    width: auto;
  //  max-width: 33%;
  }
}

body > .page-alert {
  position: fixed;
  top: 1rem;
  left: 25%;
  right: 25%;
  max-width: none;
}
