.date {
  display: flex;
  align-items: flex-start;

  &__field,
  input {
    flex: 1 0 20%;
    margin: 0 0.25rem 0 0;

    &:last-child {
      margin: 0;
    }

    &--month {
      flex: 1 0 10%;
    }

    &--day {
      flex: 1 0 10%;
    }
  }

  &__separator {
    display: inline-block;
    height: 2.5rem;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.5rem;
    padding: 0 0.4325rem 0 0.125rem;
  }
}
