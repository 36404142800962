.badge {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  max-width: 6em !important;
  padding: 0;
  text-align: center;
  border: 2px solid $brand-primary;
  margin: 0 0 0.25em;
  font-size: 0.75rem;

  &__label {
    width: 100%;
    order: 0;
    background-color: $brand-primary;
    color: $brand-white;
    padding: 0.25em 0.5em;
    text-transform: capitalize;
  }

  &__value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.75rem;
    order: 1;
    padding: 0.3125em 0.5em;
    text-align: center;
    text-transform: capitalize;
  }

  + .badge {
    clear: right;
    margin: 0 0 0.25em 0.25em;
  }

  &.cxt {
    &-promoter {
      border-color: $brand-success;

      & .badge__label {
        background-color: $brand-success;
      }
    }

    &-passive {
      border-color: $brand-warning;

      & .badge__label {
        background-color: $brand-warning;
      }
    }

    &-detractor {
      border-color: $brand-danger;

      & .badge__label {
        background-color: $brand-danger;
      }
    }

    &-other {
      border-color: $brand-secondary;

      & .badge__label {
        background-color: $brand-secondary;
      }
    }
  }

  &--small {
    // position: absolute;
    // top: 0.5rem !important;
    // right: 0.5rem !important;
    border-radius: 0.5rem;
    max-width: 3.125rem !important;

    & .badge {
      &__label {
        padding: 0.0625rem 0.125rem;
        font-size: 0.9375rem;
        font-weight: 500;
        text-transform: uppercase;

        &--bottom {
          // order: 2;
          font-size: 0.875rem;

          & + .badge__value {
            height: 1.25rem;
            font-size: 1rem;
            padding: 0.125rem 0.5rem;
          }
        }
      }

      &__value {
        height: 2rem;
        padding: 1rem 0.5rem;
        font-size: 1.25rem;
        overflow: hidden;
      }
    }
  }

  &--dark {
    border-color: $brand-dark-grey;

    & .badge__label {
      background-color: $brand-dark-grey;
    }
  }
  @media (min-width: $screen-sm) {
    margin: 0 0 1em;

    + .badge {
      clear: none;
      margin: 0 0 1rem 0.25em;
    }
  }
}
